import React, { useContext, useState } from 'react';
import { Dropdown, Form, Button, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from 'context/Context';
import { useGetUsersQuery } from 'services/users.service';
import Avatar from 'components/common/Avatar';
import { useShowBoardQuery } from 'services/boards.service';
import { useParams } from 'react-router-dom';
import { useCreateViewerMutation } from 'services/viewers.service';

const InviteToBoard = () => {
  const [filter, setFilter] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);

  const { id } = useParams();

  const {
    config: { isRTL }
  } = useContext(AppContext);

  const { data: users } = useGetUsersQuery();
  const { data: board } = useShowBoardQuery(id);

  const [createViewer] = useCreateViewerMutation();

  const viewers = board?.viewers;

  const noViewers = users?.filter(
    user => !viewers?.find(viewer => viewer.user.id === user.id)
  );

  // Filter the users based on the filter text
  const filteredUsers = noViewers?.filter(user =>
    user.name.toLowerCase().includes(filter.toLowerCase())
  );

  const handleClick = userId => {
    setSelectedUsers(prevUsers => {
      if (prevUsers.includes(userId)) {
        return prevUsers.filter(user => user !== userId);
      }
      return [...prevUsers, userId];
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    await createViewer({ board_id: id, users: selectedUsers });
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="falcon-default"
        size="sm"
        className="dropdown-caret-none"
      >
        <FontAwesomeIcon icon="user-plus" />
      </Dropdown.Toggle>
      <Dropdown.Menu
        align={isRTL ? 'start' : 'end'}
        className="pt-2 pb-0 text-nowrap end-0"
        style={{ minWidth: '300px' }}
      >
        <h6 className="dropdown-header text-center ls">Añadir a tablero</h6>
        <Dropdown.Divider className="mb-0" />
        <div className="p-3">
          <Form onSubmit={handleSubmit}>
            <Form.Control
              size="sm"
              type="text"
              placeholder="Ingresa el nombre del usuario"
              onChange={e => setFilter(e.target.value)}
            />
            <ListGroup
              className="my-3 overflow-y-auto scrollbar"
              style={{ maxHeight: '200px', overflowY: 'auto' }}
            >
              {filteredUsers?.map(user => (
                <ListGroup.Item
                  key={user.id}
                  action
                  active={selectedUsers.includes(user.id)}
                  className="d-flex align-items-center gap-2 fw-bold"
                  onClick={e => {
                    e.preventDefault();
                    handleClick(user.id);
                  }}
                >
                  {user.avatar ? (
                    <Avatar src={user.avatar} />
                  ) : (
                    <Avatar name={user.name} />
                  )}
                  {user.name}
                </ListGroup.Item>
              ))}
            </ListGroup>
            <Button
              type="submit"
              variant="primary"
              size="sm"
              className="d-block w-100 mt-2"
            >
              {selectedUsers.length > 1 ? 'Añadir Usuarios' : 'Añadir Usuario'}
            </Button>
          </Form>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default InviteToBoard;
