import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useUsers } from 'hooks/useUsers';
import { Button, Form, Modal, Row, Col, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setModal, setUser } from 'slices/users.slice';
import { useGetCompaniesQuery } from 'services/companies.service';
import {
  useCreateUserMutation,
  useUpdateUserMutation
} from 'services/users.service';
import { toast } from 'react-toastify';

const schema = yup.object().shape({
  firstName: yup.string().required('Ingresa un nombre'),
  lastName: yup.string().required('Ingresa un apellido'),
  email: yup
    .string()
    .email('Correo electrónico inválido')
    .required('Debes ingresar un correo electrónico'),
  password: yup.string().required('Debes escribir una contraseña'),
  phone: yup
    .string()
    .nullable()
    .transform(value => (value === '' ? null : value))
    .min(10, 'El número de teléfono debe tener exactamente 10 dígitos'),
  whatsapp: yup
    .string()
    .nullable()
    .transform(value => (value === '' ? null : value))
    .min(10, 'El número de WhatsApp debe tener exactamente 10 dígitos'),
  position: yup.string(),
  company: yup.string().required('Debes seleccionar una compañía'),
  role: yup.string()
});

const editSchema = yup.object().shape({
  firstName: yup.string().required('Ingresa un nombre'),
  lastName: yup.string().required('Ingresa un apellido'),
  email: yup
    .string()
    .email('Correo electrónico inválido')
    .required('Debes ingresar un correo electrónico'),
  phone: yup
    .string()
    .nullable()
    .transform(value => (value === '' ? null : value))
    .min(10, 'El número de teléfono debe tener exactamente 10 dígitos'),
  whatsapp: yup
    .string()
    .nullable()
    .transform(value => (value === '' ? null : value))
    .min(10, 'El número de WhatsApp debe tener exactamente 10 dígitos'),
  position: yup.string(),
  company: yup.string().required('Debes seleccionar una compañía'),
  role: yup.string()
});

const UsersForm = () => {
  const dispatch = useDispatch();
  const { modal, user } = useUsers();
  const { data: companies } = useGetCompaniesQuery();
  const [createUser, { isLoading }] = useCreateUserMutation();
  const [updateUser, { isLoading: isLoading2 }] = useUpdateUserMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(user ? editSchema : schema)
  });

  useEffect(() => {
    if (user) {
      reset({
        firstName: user.name.split(' ')[0],
        lastName: user.name.split(' ')[1],
        phone: user.phone,
        whatsapp: user.whatsapp,
        company: user.company_id,
        position: user.position,
        role: user.role
      });
    } else {
      reset({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        whatsapp: '',
        company: '',
        position: '',
        role: ''
      });
    }
  }, [user, reset]);

  const handleClose = () => {
    reset();
    dispatch(setUser(null));
    dispatch(setModal(false));
  };

  const onSubmit = async values => {
    const {
      firstName,
      lastName,
      email,
      password,
      phone,
      whatsapp,
      company,
      role,
      position
    } = values;

    const payload = {
      first_name: firstName,
      last_name: lastName,
      phone,
      whatsapp,
      position,
      company_id: company,
      email,
      password,
      role: role === '' ? 'user' : role
    };

    try {
      if (user) await updateUser({ id: user.id, user: payload }).unwrap();
      else await createUser(payload).unwrap();
      handleClose();
      toast.success(user ? 'Usuario editado' : 'Usuario creado', {
        position: 'top-center'
      });
    } catch (error) {
      if (error.status === 500) {
        toast.error('Ha ocurrido un error inesperado');
        return;
      }
      toast.error(error.data.error, { position: 'top-center' });
    }
  };

  return (
    <Modal show={modal} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="text-uppercase fw-bold"
        >
          {user ? 'Editar Usuario' : 'Añadir Usuario'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="p-2" onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3">
            <Form.Group as={Col} xs={12} md={6} controlId="firstName">
              <Form.Label className="fw-bold text-uppercase">
                Nombre <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                {...register('firstName')}
                isInvalid={errors.firstName}
              />
              <p className="text-danger">{errors.firstName?.message}</p>
            </Form.Group>

            <Form.Group as={Col} xs={12} md={6} controlId="lastName">
              <Form.Label className="fw-bold text-uppercase">
                Apellido <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                {...register('lastName')}
                isInvalid={errors.lastName}
              />
              <p className="text-danger">{errors.lastName?.message}</p>
            </Form.Group>
          </Row>

          {!user && (
            <Row className="mb-3">
              <Form.Group as={Col} xs={12} md={user ? 12 : 6} controlId="email">
                <Form.Label className="fw-bold text-uppercase">
                  Correo Electronico <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  {...register('email')}
                  isInvalid={errors.email}
                />
                <p className="text-danger">{errors.email?.message}</p>
              </Form.Group>

              <Form.Group as={Col} xs={12} md={6} controlId="password">
                <Form.Label className="fw-bold text-uppercase">
                  Contraseña <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="password"
                  {...register('password')}
                  isInvalid={errors.password}
                />
                <p className="text-danger">{errors.password?.message}</p>
              </Form.Group>
            </Row>
          )}
          <Row className="mb-3">
            <Form.Group as={Col} xs={12} md={6} controlId="phone">
              <Form.Label className="fw-bold text-uppercase">
                Teléfono
              </Form.Label>
              <Form.Control
                type="tel"
                {...register('phone')}
                isInvalid={errors.phone}
              />
              <p className="text-danger">{errors.phone?.message}</p>
            </Form.Group>

            <Form.Group as={Col} xs={12} md={6} controlId="whatsapp">
              <Form.Label className="fw-bold text-uppercase">
                Whatsapp
              </Form.Label>
              <Form.Control
                type="tel"
                {...register('whatsapp')}
                isInvalid={errors.whatsapp}
              />
              <p className="text-danger">{errors.whatsapp?.message}</p>
            </Form.Group>
          </Row>
          <Row className="mb-3 gap-3 gap-md-0">
            <Form.Group as={Col} xs={12} md={4} controlId="position">
              <Form.Label className="fw-bold text-uppercase">
                Posición
              </Form.Label>
              <Form.Control {...register('position')} />
            </Form.Group>

            <Form.Group as={Col} xs={12} md={4} controlId="company">
              <Form.Label className="fw-bold text-uppercase">
                Compañia <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select {...register('company')} isInvalid={errors.company}>
                <option value="">-- Seleccione --</option>
                {companies?.map(company => (
                  <option key={company.id} value={company.id}>
                    {company.name}
                  </option>
                ))}
              </Form.Select>
              <p className="text-danger">{errors.company?.message}</p>
            </Form.Group>

            <Form.Group as={Col} xs={12} md={4} controlId="role">
              <Form.Label className="fw-bold text-uppercase">Rol</Form.Label>
              <Form.Select {...register('role')}>
                <option value="">Usuario</option>
                <option value="sales">Ventas</option>
                <option value="admin">Administrador</option>
              </Form.Select>
            </Form.Group>
          </Row>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="primary" type="submit">
              {isLoading || isLoading2 ? (
                <Spinner size="sm" />
              ) : user ? (
                'Guardar'
              ) : (
                'Añadir'
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default UsersForm;
