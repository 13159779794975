import { createSlice } from '@reduxjs/toolkit';

const initialState = { modal: false, company: null };

const companiesSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setModal(state, action) {
      state.modal = action.payload;
    },
    setCompany(state, action) {
      state.company = action.payload;
    }
  }
});

export const { setModal, setCompany } = companiesSlice.actions;

export default companiesSlice.reducer;
