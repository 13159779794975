import { KanbanContext } from 'context/Context';
import { useContext } from 'react';

export const useOrderId = () => {
  const {
    kanbanState: { kanbanModal }
  } = useContext(KanbanContext);

  return kanbanModal.modalContent.id;
};
