import api from './api';

export const viewersService = api.injectEndpoints({
  endpoints: builder => ({
    getViewers: builder.query({
      query: () => 'viewers',
      providesTags: ['Viewers'],
      transformResponse: res => res.result
    }),
    showViewer: builder.query({
      query: id => `viewers/${id}`
    }),
    createViewer: builder.mutation({
      query: viewer => ({
        url: 'viewers',
        method: 'POST',
        body: viewer
      }),
      invalidatesTags: ['Viewers', 'Board']
    }),
    updateViewer: builder.mutation({
      query: ({ id, viewer }) => ({
        url: `viewers/${id}`,
        method: 'PUT',
        body: viewer
      }),
      invalidatesTags: ['Viewers', 'Board']
    }),
    deleteViewer: builder.mutation({
      query: id => ({
        url: `viewers/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Viewers', 'Board']
    })
  })
});

export const {
  useGetViewersQuery,
  useShowViewerQuery,
  useCreateViewerMutation,
  useUpdateViewerMutation,
  useDeleteViewerMutation
} = viewersService;
