import { createSlice } from '@reduxjs/toolkit';

const initialState = { modal: false, user: null };

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setModal(state, action) {
      state.modal = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    }
  }
});

export const { setModal, setUser } = usersSlice.actions;

export default usersSlice.reducer;
