import React from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { useAuth } from 'hooks/useAuth';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useUpdateUserMutation } from 'services/users.service';
import { useDispatch } from 'react-redux';
import { setCurrentUser } from 'slices/authentication.slice';

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('Campo obligatorio'),
  lastName: Yup.string().required('Campo obligatorio'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone: Yup.string().min(10, 'Número inválido'),
  heading: Yup.string()
});

const ProfileSettings = () => {
  const dispatch = useDispatch();
  const [updateUser, { isLoading }] = useUpdateUserMutation();
  const user = useAuth();
  const { id, name, email, phone, position } = user;

  const nameParts = name.split(' ');
  const firstName = nameParts[0];
  const lastName = nameParts.slice(1).join(' ');

  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      heading: position
    },
    resolver: yupResolver(validationSchema)
  });

  const { errors } = formState;

  const onSubmit = async values => {
    const payload = {
      first_name: values.firstName,
      last_name: values.lastName,
      phone: values.phone,
      email: values.email,
      position: values.heading
    };

    const userUpdated = await updateUser({ id, user: payload }).unwrap();

    dispatch(setCurrentUser(userUpdated.result));
  };

  return (
    <Card>
      <FalconCardHeader title="Ajustes del perfil" />
      <Card.Body className="bg-light">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={6} controlId="firstName">
              <Form.Label>
                Nombre <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre"
                isInvalid={errors.firstName}
                {...register('firstName')}
              />
              {errors.firstName && (
                <div className="text-danger">{errors.firstName.message}</div>
              )}
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="lastName">
              <Form.Label>
                Apellido <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Apellido"
                isInvalid={errors.lastName}
                {...register('lastName')}
              />
              {errors.lastName && (
                <div className="text-danger">{errors.lastName.message}</div>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={6} controlId="email">
              <Form.Label>
                Correo electrónico <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Correo electrónico"
                isInvalid={errors.email}
                {...register('email')}
              />
              {errors.email && (
                <div className="text-danger">{errors.email.message}</div>
              )}
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="phone">
              <Form.Label>Teléfono</Form.Label>
              <Form.Control
                type="text"
                placeholder="Teléfono"
                isInvalid={errors.phone}
                {...register('phone')}
              />
              {errors.phone && (
                <div className="text-danger">{errors.phone.message}</div>
              )}
            </Form.Group>
          </Row>

          <Form.Group className="mb-3" controlId="heading">
            <Form.Label>Posición</Form.Label>
            <Form.Control
              type="text"
              placeholder="Heading"
              {...register('heading')}
            />
          </Form.Group>

          <div className="text-end">
            <Button variant="primary" type="submit">
              {isLoading ? <Spinner size="sm" /> : 'Actualizar'}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ProfileSettings;
