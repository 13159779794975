import api from './api';

export const reviewersService = api.injectEndpoints({
  endpoints: builder => ({
    getReviewers: builder.query({
      query: () => 'reviewers',
      providesTags: ['Reviewers'],
      transformResponse: res => res.result
    }),
    showReviewer: builder.query({
      query: id => `reviewers/${id}`
    }),
    createReviewer: builder.mutation({
      query: reviewer => ({
        url: 'reviewers',
        method: 'POST',
        body: reviewer
      }),
      invalidatesTags: ['Reviewers', 'Board', 'Order']
    }),
    updateReviewer: builder.mutation({
      query: ({ id, reviewer }) => ({
        url: `reviewers/${id}`,
        method: 'PUT',
        body: reviewer
      }),
      invalidatesTags: ['Reviewers', 'Board']
    }),
    deleteReviewer: builder.mutation({
      query: id => ({
        url: `reviewers/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Reviewers', 'Board', 'Order']
    })
  })
});

export const {
  useGetReviewersQuery,
  useShowReviewerQuery,
  useCreateReviewerMutation,
  useUpdateReviewerMutation,
  useDeleteReviewerMutation
} = reviewersService;
