import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useDeleteLaneMutation,
  useUpdateLaneMutation
} from 'services/lanes.service';
import DeleteConfirmation from 'components/common/DeleteConfirmation';

const KanbanColumnHeader = ({ id, title, itemCount, isAllowed }) => {
  const [show, setShow] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [name, setName] = useState(title);

  const [updateLane] = useUpdateLaneMutation();
  const [deleteLane, { isLoading }] = useDeleteLaneMutation();

  const handleRemoveColumn = () => deleteLane(id);

  const handleSubmit = e => {
    e.preventDefault();

    if (!name) {
      setShowInput(false);
      return;
    }

    updateLane({ id, lane: { name } });
    setShowInput(false);
  };

  return (
    <div className="kanban-column-header">
      {showInput ? (
        <Form onSubmit={handleSubmit}>
          <Form.Control
            placeholder="Ingresa un nombre"
            value={name}
            onChange={({ target }) => setName(target.value)}
          />
        </Form>
      ) : (
        <h5 className="fs-0 mb-0">
          {title} <span className="text-500">({itemCount})</span>
        </h5>
      )}
      {isAllowed && (
        <Dropdown align="end" className="font-sans-serif btn-reveal-trigger">
          <Dropdown.Toggle variant="reveal" size="sm" className="py-0 px-2">
            <FontAwesomeIcon icon="ellipsis-h" />
          </Dropdown.Toggle>

          <Dropdown.Menu className="py-0">
            <Dropdown.Item onClick={() => setShowInput(true)}>
              Renombrar
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => setShow(true)}
              className="text-danger"
            >
              Eliminar
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
      <DeleteConfirmation
        show={show}
        onHide={() => setShow(false)}
        isLoading={isLoading}
        handleDelete={handleRemoveColumn}
        msg={`¿Deseas eliminar la columna ${title}?`}
      />
    </div>
  );
};

KanbanColumnHeader.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  itemCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isAllowed: PropTypes.bool
};

export default KanbanColumnHeader;
