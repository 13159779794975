import React, { useContext, useMemo, useState } from 'react';
import UserDropdownList from './UserDropdownList';
import { Button, Dropdown, Form, Nav } from 'react-bootstrap';
import AppContext from 'context/Context';
import {
  useCreateReviewerMutation,
  useDeleteReviewerMutation
} from 'services/reviewers.service';
import {
  useCreateTagMutation,
  useDeleteTagMutation,
  useGetTagsQuery
} from 'services/tags.service';
import { useUpdateOrderMutation } from 'services/orders.service';
import FileInput from 'components/common/FileInput';
import { useCreateAttachmentMutation } from 'services/attachment.service';
import {
  useCreateGroupMutation,
  useGetGroupsQuery
} from 'services/group.service';
import transformGroups from 'helpers/transformGroups';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TagsFormChecklist from './TagsFormChecklist';
import PropTypes from 'prop-types';

const AddToCard = ({ menuList, board, order }) => {
  const {
    config: { isRTL }
  } = useContext(AppContext);

  const [group, setGroup] = useState(null);
  const [title, setTitle] = useState('');

  const [updateOrder] = useUpdateOrderMutation();

  const [createReviewer] = useCreateReviewerMutation();
  const [deleteReviewer] = useDeleteReviewerMutation();

  const { data: tags } = useGetTagsQuery();
  const [addTag] = useCreateTagMutation();
  const [deleteTag] = useDeleteTagMutation();

  const [createAttachment] = useCreateAttachmentMutation();

  const { data } = useGetGroupsQuery();
  const [createGroup] = useCreateGroupMutation();

  const orders = useMemo(() => transformGroups(data), [data]);

  const viewers = board.viewers;
  const reviewers = order.reviewers;
  const labels = order.tags;

  const handleAdd = id => {
    createReviewer({ order_id: order.id, user_id: id });
  };

  const handleRemove = id => deleteReviewer(id);

  const handleTagChange = (e, tag) => {
    if (e.target.checked) {
      addTag({ tag_id: tag.id, order_id: order.id });
    } else {
      const label = labels.find(label => label.id === tag.id);
      deleteTag(label.tag_order_id);
    }
  };

  const handleChange = e => {
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    updateOrder({ id: order.id, order: formData });
  };

  const handleUpload = e => {
    const formData = new FormData();
    const file = e.target.files[0];
    const fileName = file?.name;
    const extension = fileName.split('.').pop();

    formData.append('src', file);
    formData.append('order_id', order.id);
    formData.append('name', fileName);
    formData.append('title', fileName);
    formData.append('archive_type', extension.toLowerCase());

    createAttachment(formData);
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (!title) return;

    createGroup({
      name: title,
      order_id: order.id,
      group_id: group?.split(',')[0]
    });
    setTitle('');
    setGroup(null);
  };

  return (
    <>
      <h6 className="mt-5 mt-lg-0">Añadir a orden</h6>
      {menuList.map(menu => (
        <Nav key={menu.title} className="flex-lg-column fs--1">
          <Nav.Item className="me-2 me-lg-0">
            <Dropdown>
              <Dropdown.Toggle
                as={Nav.Link}
                className="nav-link-card-details dropdown-caret-none"
              >
                <FontAwesomeIcon icon={menu.icon} className="me-2" />
                {menu.title}
              </Dropdown.Toggle>

              <Dropdown.Menu
                align={isRTL ? 'end' : 'start'}
                className="dropdown-md"
              >
                {menu.title === 'Revisores' ? (
                  <UserDropdownList
                    viewers={viewers}
                    users={reviewers}
                    handleAdd={handleAdd}
                    handleRemove={handleRemove}
                  />
                ) : menu.title === 'Etiquetas' ? (
                  tags && (
                    <TagsFormChecklist
                      tags={tags}
                      labels={labels}
                      handleTagChange={handleTagChange}
                    />
                  )
                ) : menu.title === 'Imagen' ? (
                  !order.image && (
                    <FileInput
                      formClassName="d-flex justify-content-center align-items-center"
                      labelClassName="btn btn-light shadow-sm"
                      acceptFiles="image/*"
                      controlId="order-image"
                      onChange={handleChange}
                    >
                      Subir Imagen
                    </FileInput>
                  )
                ) : menu.title === 'Archivos' ? (
                  <FileInput
                    formClassName="d-flex justify-content-center align-items-center"
                    labelClassName="btn btn-light shadow-sm"
                    controlId="order-attachment"
                    onChange={handleUpload}
                  >
                    Subir Archivo
                  </FileInput>
                ) : (
                  <>
                    <Dropdown.Header>Añadir grupo</Dropdown.Header>
                    <Dropdown.ItemText>
                      <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-2">
                          <Form.Label>Título</Form.Label>
                          <Form.Control
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>Copiar tareas</Form.Label>
                          <Form.Select
                            value={group ?? ''}
                            onChange={e => {
                              const group = e.target.value.split(',');
                              setGroup(e.target.value);
                              setTitle(group[1]);
                            }}
                          >
                            <option value="">(ninguno)</option>
                            {orders?.map(order => (
                              <optgroup key={order.id} label={order.name}>
                                {order.groups.map(group => (
                                  <option
                                    key={group.id}
                                    value={[group.id, group.name]}
                                  >
                                    {group.name}
                                  </option>
                                ))}
                              </optgroup>
                            ))}
                          </Form.Select>
                        </Form.Group>
                        <Button className="mt-3" size="sm" type="submit">
                          Añadir
                        </Button>
                      </Form>
                    </Dropdown.ItemText>
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Nav.Item>
        </Nav>
      ))}
    </>
  );
};

AddToCard.propTypes = {
  menuList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.any.isRequired
    })
  ).isRequired,
  board: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired
};

export default AddToCard;
