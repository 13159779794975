import { yupResolver } from '@hookform/resolvers/yup';
import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Form, Row, InputGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useGetCompaniesQuery } from 'services/companies.service';
import { useCreateOrderMutation } from 'services/orders.service';
import * as yup from 'yup';

const schema = yup.object().shape({
  name: yup.string().required('Ingresa un nombre'),
  company: yup.string().required('Debes seleccionar una compañía')
});

const CustomersTableHeader = () => {
  const [showForm, setShowForm] = useState(false);
  const { data: companies } = useGetCompaniesQuery();
  const [createOrder, { isLoading }] = useCreateOrderMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async data => {
    const { name, company } = data;

    createOrder({ name, company_id: company });
    reset();
    setShowForm(false);
  };

  return (
    <Row className="flex-between-center">
      <Col
        xs={showForm ? 12 : 4}
        sm="auto"
        className="d-flex align-items-center pe-0"
      >
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Ordenes</h5>
      </Col>
      <Col xs={showForm ? 12 : 8} sm="auto" className="ms-auto text-end ps-0">
        {showForm ? (
          <Form
            onSubmit={handleSubmit(onSubmit)}
            className="d-flex flex-column flex-md-row"
          >
            <InputGroup className="gap-2">
              <Col xs={12} md="auto" className="p-0">
                <Form.Control
                  className="me-2 rounded-2"
                  type="text"
                  placeholder="Nombre de la orden"
                  {...register('name')}
                  isInvalid={!!errors.name}
                />
              </Col>
              <Col xs={12} md="auto" className="p-0">
                <Form.Select
                  className="me-2 rounded-2"
                  {...register('company')}
                  isInvalid={!!errors.company}
                >
                  {companies?.map(company => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col
                xs={12}
                md="auto"
                className="p-0 d-flex align-items-start align-items-md-center gap-2 mt-2 mt-md-0"
              >
                <IconButton
                  className="w-100"
                  variant="primary"
                  size="sm"
                  icon="check"
                  onClick={handleSubmit(onSubmit)}
                  disabled={isLoading}
                />
                <IconButton
                  className="w-100"
                  variant="danger"
                  size="sm"
                  icon="times"
                  onClick={() => setShowForm(false)}
                />
              </Col>
            </InputGroup>
          </Form>
        ) : (
          <div id="orders-actions">
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-3"
              onClick={() => setShowForm(true)}
            >
              <span className="d-none d-sm-inline-block ms-1">Nuevo</span>
            </IconButton>
            {/* <IconButton
              variant="falcon-default"
              size="sm"
              icon="filter"
              transform="shrink-3"
              className="mx-2"
            >
              <span className="d-none d-sm-inline-block ms-1">Filter</span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="external-link-alt"
              transform="shrink-3"
            >
              <span className="d-none d-sm-inline-block ms-1">Export</span>
            </IconButton> */}
          </div>
        )}
      </Col>
    </Row>
  );
};

CustomersTableHeader.propTypes = {
  selectedRowIds: PropTypes.object
};

export default CustomersTableHeader;
