import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useAuthenticateMutation } from 'services/authentication.service';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Correo inválido')
    .required('Debes ingresar tu usuario'),
  password: Yup.string().required('Debes ingresar tu contraseña')
});

const LoginForm = ({ hasLabel }) => {
  const navigate = useNavigate();
  const [authenticate] = useAuthenticateMutation();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = async values => {
    try {
      await authenticate(values).unwrap();

      toast.success(`Iniciaste sesión cómo ${values.email}`, {
        theme: 'colored'
      });
      navigate('/');
    } catch (error) {
      toast.error(
        'Ha ocurrido un error, por favor revisa tu usuario y contraseña'
      );
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Usuario</Form.Label>}
        <Form.Control
          type="email"
          placeholder={!hasLabel ? 'Usuario' : ''}
          isInvalid={errors.email}
          {...register('email')}
        />
        {errors.email && (
          <Form.Control.Feedback type="invalid">
            {errors.email.message}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Contraseña</Form.Label>}
        <Form.Control
          type="password"
          placeholder={!hasLabel ? 'Contraseña' : ''}
          isInvalid={errors.password}
          {...register('password')}
        />
        {errors.password && (
          <Form.Control.Feedback type="invalid">
            {errors.password.message}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Row className="justify-content-end">
        <Col xs="auto">
          <Link className="fs--1 mb-0" to="/authentication/forgot-password">
            ¿Olvidaste tu contraseña?
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            'Entrar'
          )}
        </Button>
      </Form.Group>
    </Form>
  );
};

LoginForm.propTypes = {
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  hasLabel: false
};

export default LoginForm;
