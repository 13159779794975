import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Background from 'components/common/Background';
import Avatar from 'components/common/Avatar';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from 'context/Context';
import FileInput from 'components/common/FileInput';
import { useUpdateCurrentUserMutation } from 'services/users.service';
import { useAuth } from 'hooks/useAuth';

const ProfileBannerHeader = ({
  avatar,
  name,
  coverSrc,
  className,
  isEditable
}) => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  const user = useAuth();
  const [updateCurrentUser] = useUpdateCurrentUserMutation();

  const handleChange = async e => {
    const avatarImg = e.target.files[0];

    const formData = new FormData();
    formData.append('avatar', avatarImg);
    updateCurrentUser({ id: user.id, user: formData });
  };

  return (
    <Card.Header
      className={classNames(className, 'position-relative min-vh-25 mb-7')}
    >
      {isEditable ? (
        <>
          <Background image={coverSrc} className="rounded-3 rounded-bottom-0" />
          <div className="avatar avatar-5xl avatar-profile shadow-sm img-thumbnail rounded-circle">
            <div className="h-100 w-100 rounded-circle overflow-hidden position-relative">
              <img src={avatar} width="200" alt="" />
              <FileInput
                controlId="profile-image"
                labelClassName="mb-0 overlay-icon d-flex flex-center"
                onChange={handleChange}
              >
                <span className="bg-holder overlay overlay-0" />
                <span
                  className={`z-index-1 text-center fs--1 ${
                    isDark ? 'text-dark' : 'text-white'
                  }`}
                >
                  <FontAwesomeIcon icon="camera" />
                  <span className="d-block">Cambiar</span>
                </span>
              </FileInput>
            </div>
          </div>
        </>
      ) : (
        <>
          <Background image={coverSrc} className="rounded-3 rounded-bottom-0" />
          {avatar ? (
            <Avatar
              size="5xl"
              className="avatar-profile"
              src={avatar}
              mediaClass="img-thumbnail shadow-sm"
            />
          ) : (
            <Avatar
              size="5xl"
              className="avatar-profile"
              name={name}
              mediaClass="img-thumbnail shadow-sm"
            />
          )}
        </>
      )}
    </Card.Header>
  );
};

const ProfileBannerBody = ({ children }) => {
  return <Card.Body>{children}</Card.Body>;
};

const ProfileBanner = ({ children }) => {
  return <Card className="mb-3">{children}</Card>;
};

ProfileBanner.Header = ProfileBannerHeader;
ProfileBanner.Body = ProfileBannerBody;

ProfileBannerHeader.propTypes = {
  avatar: PropTypes.string.isRequired,
  coverSrc: PropTypes.string.isRequired,
  className: PropTypes.string,
  isEditable: PropTypes.bool,
  name: PropTypes.string
};

ProfileBannerBody.propTypes = {
  children: PropTypes.node.isRequired
};

ProfileBanner.propTypes = {
  children: PropTypes.node.isRequired
};

export default ProfileBanner;
