import api from './api';

export const messagesService = api.injectEndpoints({
  endpoints: builder => ({
    getMessages: builder.query({
      query: () => 'messages',
      providesTags: ['Messages'],
      transformResponse: res => res.result
    }),
    showMessage: builder.query({
      query: id => `messages/${id}`
    }),
    createMessage: builder.mutation({
      query: message => ({
        url: 'messages',
        method: 'POST',
        body: message
      }),
      invalidatesTags: ['Messages', 'Board', 'Order']
    }),
    updateMessage: builder.mutation({
      query: ({ id, message }) => ({
        url: `messages/${id}`,
        method: 'PUT',
        body: message
      }),
      invalidatesTags: ['Messages', 'Board', 'Order']
    }),
    deleteMessage: builder.mutation({
      query: id => ({
        url: `messages/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Messages', 'Board', 'Order']
    })
  })
});

export const {
  useGetMessagesQuery,
  useShowMessageQuery,
  useCreateMessageMutation,
  useUpdateMessageMutation,
  useDeleteMessageMutation
} = messagesService;
