import api from './api';

export const companiesService = api.injectEndpoints({
  endpoints: builder => ({
    getCompanies: builder.query({
      query: () => 'companies',
      providesTags: ['Companies'],
      transformResponse: response => response.result
    }),
    showCompany: builder.query({
      query: id => `companies/${id}`
    }),
    createCompany: builder.mutation({
      query: company => ({
        url: 'companies',
        method: 'POST',
        body: company
      }),
      invalidatesTags: ['Companies']
    }),
    updateCompany: builder.mutation({
      query: ({ id, company }) => ({
        url: `companies/${id}`,
        method: 'PUT',
        body: company
      }),
      invalidatesTags: ['Companies']
    }),
    deleteCompany: builder.mutation({
      query: id => ({
        url: `companies/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Companies']
    })
  })
});

export const {
  useGetCompaniesQuery,
  useShowCompanyQuery,
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  useDeleteCompanyMutation
} = companiesService;
