import React, { useContext, useEffect, useRef, useState } from 'react';
import KanbanColumn from './KanbanColumn';
import KanbanModal from './KanbanModal';
import { DragDropContext } from 'react-beautiful-dnd';
import is from 'is_js';
import { KanbanContext } from 'context/Context';
import { useParams } from 'react-router-dom';
import { useShowBoardQuery } from 'services/boards.service';
import { useUpdateOrderMutation } from 'services/orders.service';

const KanbanContainer = () => {
  const {
    kanbanState: { kanbanModal },
    kanbanDispatch
  } = useContext(KanbanContext);
  const [lanes, setLanes] = useState([]);
  const containerRef = useRef(null);

  const { id } = useParams();

  const { data: board } = useShowBoardQuery(id);
  const [updateOrder] = useUpdateOrderMutation();

  useEffect(() => {
    setLanes(board?.lanes);
  }, [board]);

  useEffect(() => {
    if (is.ipad()) {
      containerRef.current.classList.add('ipad');
    }

    if (is.mobile()) {
      containerRef.current.classList.add('mobile');
      if (is.safari()) {
        containerRef.current.classList.add('safari');
      }
      if (is.chrome()) {
        containerRef.current.classList.add('chrome');
      }
    }
  }, []);

  const getColumn = id => {
    return lanes.find(item => item.id === Number(id));
  };

  const reorderArray = (array, fromIndex, toIndex) => {
    const newArr = [...array];

    const chosenItem = newArr.splice(fromIndex, 1)[0];
    newArr.splice(toIndex, 0, chosenItem);

    return newArr;
  };

  const move = (source, destination) => {
    const sourceItemsClone = [...getColumn(source.droppableId).orders];
    const destItemsClone = [...getColumn(destination.droppableId).orders];

    const [removedItem] = sourceItemsClone.splice(source.index, 1);
    destItemsClone.splice(destination.index, 0, removedItem);

    return {
      updatedDestItems: destItemsClone,
      updatedSourceItems: sourceItemsClone
    };
  };

  const handleDragEnd = result => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = getColumn(source.droppableId).orders;
      const column = getColumn(source.droppableId);
      const reorderedItems = reorderArray(
        items,
        source.index,
        destination.index
      );

      kanbanDispatch({
        type: 'UPDATE_SINGLE_COLUMN',
        payload: { column, reorderedItems }
      });
    } else {
      const sourceColumn = getColumn(source.droppableId);
      const destColumn = getColumn(destination.droppableId);

      const movedItems = move(source, destination);

      const order = movedItems.updatedDestItems.find(
        order => order.lane_id !== destColumn.id
      );

      const updatedLanes = lanes.map(lane => {
        if (lane.id === sourceColumn.id) {
          return { ...lane, orders: movedItems.updatedSourceItems };
        } else if (lane.id === destColumn.id) {
          return { ...lane, orders: movedItems.updatedDestItems };
        } else {
          return lane;
        }
      });

      setLanes(updatedLanes);
      updateOrder({ id: order.id, order: { lane_id: destColumn.id } });
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className="kanban-container me-n3 scrollbar" ref={containerRef}>
        {lanes?.map(kanbanColumnItem => (
          <KanbanColumn
            key={kanbanColumnItem.id}
            kanbanColumnItem={kanbanColumnItem}
          />
        ))}
        <KanbanModal show={kanbanModal.show} />
      </div>
    </DragDropContext>
  );
};

export default KanbanContainer;
