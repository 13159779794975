import api from './api';

export const tasksService = api.injectEndpoints({
  endpoints: builder => ({
    getTasks: builder.query({
      query: () => 'tasks',
      providesTags: ['Tasks'],
      transformResponse: res => res.result
    }),
    showTask: builder.query({
      query: id => `tasks/${id}`
    }),
    createTask: builder.mutation({
      query: task => ({
        url: 'tasks',
        method: 'POST',
        body: task
      }),
      invalidatesTags: ['Tasks', 'Board', 'Order']
    }),
    updateTask: builder.mutation({
      query: ({ id, task }) => ({
        url: `tasks/${id}`,
        method: 'PUT',
        body: task
      }),
      invalidatesTags: ['Tasks', 'Board', 'Order']
    }),
    deleteTask: builder.mutation({
      query: id => ({
        url: `tasks/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Tasks', 'Board', 'Order']
    })
  })
});

export const {
  useGetTasksQuery,
  useShowTaskQuery,
  useCreateTaskMutation,
  useUpdateTaskMutation,
  useDeleteTaskMutation
} = tasksService;
