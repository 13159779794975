import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Button,
  Form,
  Modal,
  Row,
  Col,
  Spinner,
  Image,
  InputGroup
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setModal, setCompany } from 'slices/companies.slice';
import { toast } from 'react-toastify';
import { useCompanies } from 'hooks/useCompanies';
import {
  useCreateCompanyMutation,
  useUpdateCompanyMutation
} from 'services/companies.service';
import Flex from 'components/common/Flex';

const schema = yup.object().shape({
  name: yup.string().required('Ingresa un nombre'),
  rfc: yup.string().required('Ingresa el RFC')
});

const CustomersForm = () => {
  const [imagePreview, setImagePreview] = useState(null);

  const dispatch = useDispatch();
  const { modal, company } = useCompanies();
  const [createCompany, { isLoading }] = useCreateCompanyMutation();
  const [updateCompany, { isLoading: isLoading2 }] = useUpdateCompanyMutation();

  // Create a ref to keep track of the file input
  const fileInputRef = useRef();

  const onImageChange = event => {
    const file = event.target.files[0];
    if (file) {
      setImagePreview(URL.createObjectURL(file));
      fileInputRef.current = file;
    } else {
      setImagePreview(null);
      fileInputRef.current = null;
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (company) {
      reset({
        name: company.name,
        rfc: company.rfc,
        country: company.country,
        state: company.state,
        city: company.city,
        address: company.address,
        zip: company.zip_code
      });

      const logo = company.logo;

      if (logo) {
        setImagePreview(logo);
        fileInputRef.current = logo;
      }
    } else {
      reset({
        name: '',
        rfc: '',
        country: '',
        state: '',
        city: '',
        address: '',
        zip: ''
      });
    }
  }, [company, reset]);

  const handleClose = () => {
    reset();
    setImagePreview(null);
    fileInputRef.current = null;
    dispatch(setCompany(null));
    dispatch(setModal(false));
  };

  const onSubmit = async values => {
    const { name, rfc, country, state, city, address, zip } = values;

    // Retrieve the file from the file input
    const file =
      fileInputRef.current.name === undefined ? null : fileInputRef.current;

    // Create a FormData instance to hold the file and other form data
    const payload = new FormData();
    payload.append('logo', file);
    payload.append('name', name);
    payload.append('rfc', rfc);
    payload.append('country', country);
    payload.append('state', state);
    payload.append('city', city);
    payload.append('address', address);
    payload.append('zip_code', zip);

    if (!file) payload.delete('logo');

    try {
      if (company)
        await updateCompany({ id: company.id, company: payload }).unwrap();
      else await createCompany(payload).unwrap();
      handleClose();
      toast.success(company ? 'Cliente editado' : 'Cliente creado', {
        position: 'top-center'
      });
    } catch (error) {
      if (error.status === 500) {
        toast.error('Ha ocurrido un error inesperado', {
          position: 'top-center'
        });
        return;
      }
      toast.error(error.data.error, { position: 'top-center' });
    }
  };

  return (
    <Modal show={modal} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="text-uppercase fw-bold"
        >
          {company ? 'Editar Cliente' : 'Añadir Cliente'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="p-2" onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3">
            <Form.Group as={Col} xs={12} md={6} controlId="name">
              <Form.Label className="fw-bold text-uppercase">
                Nombre <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                {...register('name')}
                isInvalid={errors.name}
              />
              <p className="text-danger">{errors.name?.message}</p>
            </Form.Group>

            <Form.Group as={Col} xs={12} md={6} controlId="rfc">
              <Form.Label className="fw-bold text-uppercase">
                RFC <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                {...register('rfc')}
                isInvalid={errors.rfc}
              />
              <p className="text-danger">{errors.rfc?.message}</p>
            </Form.Group>
          </Row>

          <Row className="mb-3 gap-3 gap-md-0">
            <Form.Group as={Col} xs={12} md={4} controlId="country">
              <Form.Label className="fw-bold text-uppercase">País</Form.Label>
              <Form.Control {...register('country')} />
            </Form.Group>

            <Form.Group as={Col} xs={12} md={4} controlId="state">
              <Form.Label className="fw-bold text-uppercase">Estado</Form.Label>
              <Form.Control {...register('state')} />
            </Form.Group>

            <Form.Group as={Col} xs={12} md={4} controlId="city">
              <Form.Label className="fw-bold text-uppercase">Ciudad</Form.Label>
              <Form.Control {...register('city')} />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} xs={12} controlId="address">
              <Form.Label className="fw-bold text-uppercase">
                Dirección
              </Form.Label>
              <Form.Control {...register('address')} />
            </Form.Group>
          </Row>
          <Row className="mb-3 gap-3 gap-md-0">
            <Form.Group as={Col} xs={12} md={6} controlId="zip">
              <Form.Label className="fw-bold text-uppercase">
                Código Postal
              </Form.Label>
              <Form.Control {...register('zip')} />
            </Form.Group>
            <Form.Group as={Col} xs={12} md={6} controlId="logo">
              <Form.Label className="fw-bold text-uppercase">Logo</Form.Label>
              <InputGroup>
                <input
                  id="fileInput"
                  type="file"
                  accept="image/*"
                  onChange={onImageChange}
                  style={{ display: 'none' }}
                />
                <Button
                  as="label"
                  htmlFor="fileInput"
                  variant="outline-primary"
                  className="d-flex align-items-center justify-content-center rounded-2"
                  ref={fileInputRef}
                >
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    className="w-100 h-100"
                  >
                    {imagePreview ? (
                      <Image
                        src={imagePreview}
                        alt="preview"
                        rounded
                        fluid
                        className="m-1"
                        style={{ maxWidth: '100px' }}
                      />
                    ) : (
                      <Flex alignItems="center">
                        <i className="bi bi-upload me-2"></i>
                        <span className="p-1 m-0">Seleccionar Logo</span>
                      </Flex>
                    )}
                  </Flex>
                </Button>
              </InputGroup>
            </Form.Group>
          </Row>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="primary" type="submit">
              {isLoading || isLoading2 ? (
                <Spinner size="sm" />
              ) : company ? (
                'Guardar'
              ) : (
                'Añadir'
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CustomersForm;
