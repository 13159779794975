import { faBuilding } from '@fortawesome/free-solid-svg-icons';

export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Tableros',
      icon: ['fab', 'trello'],
      to: '/',
      active: true
    },
    {
      name: 'Usuarios',
      icon: 'users',
      to: '/users',
      active: true
    },
    {
      name: 'Clientes',
      icon: faBuilding,
      to: '/customers',
      active: true
    },
    {
      name: 'Ordenes',
      icon: 'copy',
      to: '/orders',
      active: true
    }
  ]
};

export default [dashboardRoutes];
