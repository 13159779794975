import React from 'react';
import { Form, Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';

const TagsFormChecklist = ({ tags, labels, handleTagChange }) => {
  return (
    <>
      <h6 className="dropdown-header py-0 px-3 mb-0">Seleccionar Etiqueta</h6>
      <Dropdown.Divider />
      <Form onSubmit={e => e.preventDefault()} className="px-3">
        {tags?.map(tag => (
          <Form.Check key={tag.id} id={tag.id} className="d-flex gap-2">
            <Form.Check.Input
              checked={
                labels?.find(label => label.id === tag.id) === undefined
                  ? false
                  : labels.find(label => label.id === tag.id)
              }
              onChange={e => handleTagChange(e, tag)}
            />
            <Dropdown.Item
              as="button"
              className={`badge-soft-${tag.tag_type} rounded-1 mb-2`}
            >
              {tag.text}
            </Dropdown.Item>
          </Form.Check>
        ))}
      </Form>
    </>
  );
};

TagsFormChecklist.propTypes = {
  tags: PropTypes.array,
  labels: PropTypes.array,
  handleTagChange: PropTypes.func
};

export default TagsFormChecklist;
