import { configureStore } from '@reduxjs/toolkit';
import authenticationReducer from './slices/authentication.slice';
import api from 'services/api';
import usersReducer from 'slices/users.slice';
import companiesReducer from 'slices/companies.slice';

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth: authenticationReducer,
    users: usersReducer,
    companies: companiesReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(api.middleware)
});

export default store;
