import React, { useEffect, useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { customersData } from 'data/ecommerce/customersData';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import {
  useDeleteUserMutation,
  useGetUsersQuery
} from 'services/users.service';
import { useGetCompaniesQuery } from 'services/companies.service';
import UsersForm from './UsersForm';
import { useDispatch } from 'react-redux';
import { setModal, setUser } from 'slices/users.slice';
import DeleteConfirmation from 'components/common/DeleteConfirmation';
import UsersTableHeader from './UsersTableHeader';

const columns = [
  {
    accessor: 'name',
    Header: 'Nombre',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { name, avatar } = rowData.row.original;
      return (
        <Link to="#">
          <Flex alignItems="center">
            {avatar && typeof avatar === 'string' ? (
              <Avatar src={avatar} size="xl" className="me-2" />
            ) : (
              <Avatar size="xl" name={name} className="me-2" />
            )}
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{name}</h5>
            </div>
          </Flex>
        </Link>
      );
    }
  },
  {
    accessor: 'email',
    Header: 'Correo electrónico',
    Cell: rowData => {
      const { email } = rowData.row.original;
      return (
        <div
          className="flex-1 text-truncate"
          title={email}
          style={{ maxWidth: '300px' }}
        >
          <a href={`mailto:${email}`}>{email}</a>
        </div>
      );
    }
  },
  {
    accessor: 'phone',
    Header: 'Teléfono',
    Cell: rowData => {
      const { phone } = rowData.row.original;
      return (
        <div
          className="flex-1 text-truncate"
          title={phone}
          style={{ maxWidth: '300px' }}
        >
          <a href={`tel:${phone}`}>{phone}</a>
        </div>
      );
    }
  },
  {
    accessor: 'whatsapp',
    Header: 'Whatsapp',
    Cell: rowData => {
      const { whatsapp } = rowData.row.original;
      return (
        <div
          className="flex-1 text-truncate"
          title={whatsapp}
          style={{ maxWidth: '300px' }}
        >
          <a href={`tel:${whatsapp}`}>{whatsapp}</a>
        </div>
      );
    }
  },
  {
    accessor: 'position',
    Header: 'Posición',
    Cell: rowData => {
      const { position } = rowData.row.original;

      return (
        <div
          className="flex-1 text-truncate"
          title={position}
          style={{ maxWidth: '300px' }}
        >
          {position}
        </div>
      );
    }
  },
  {
    accessor: 'role',
    Header: 'Rol',
    Cell: rowData => {
      const { role } = rowData.row.original;

      let rol;

      switch (role) {
        case 'sales':
          rol = 'Ventas';
          break;

        case 'admin':
          rol = 'Administrador';
          break;

        default:
          rol = 'Usuario';
          break;
      }

      return (
        <Flex alignItems="center">
          <div className="flex-1">
            <p className="mb-0 fs--1">{rol}</p>
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'company',
    Header: 'Compañía',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { company, companyLogo } = rowData.row.original;

      return (
        <Link to="#">
          <Flex alignItems="center">
            {companyLogo ? (
              <Avatar src={companyLogo} size="xl" className="me-2" />
            ) : (
              <Avatar size="xl" name={company} className="me-2" />
            )}
            <div
              className="flex-1 text-truncate"
              title={company}
              style={{ maxWidth: '300px' }}
            >
              <h5 className="mb-0 fs--1">{company}</h5>
            </div>
          </Flex>
        </Link>
      );
    }
  },
  {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    },
    Cell: rowData => {
      const dispatch = useDispatch();
      const [show, setShow] = useState(false);
      const [deleteUser, { isLoading }] = useDeleteUserMutation();

      const user = rowData.row.original;

      return (
        <CardDropdown iconClassName="fs--1">
          <div className="py-2">
            <Dropdown.Item
              onClick={() => {
                dispatch(setUser(user));
                dispatch(setModal(true));
              }}
            >
              Editar
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setShow(true);
              }}
            >
              Eliminar
            </Dropdown.Item>
          </div>
          <DeleteConfirmation
            msg={`¿Está seguro de querer eliminar a ${user.name}?`}
            show={show}
            onHide={() => setShow(false)}
            handleDelete={() => {
              deleteUser(user.id);
              setShow(false);
            }}
            isLoading={isLoading}
          />
        </CardDropdown>
      );
    }
  }
];

const Users = () => {
  const [enrichedUsers, setEnrichedUsers] = useState([]);
  const [customers] = useState(customersData);

  const { data, isLoading } = useGetUsersQuery();
  const { data: companies } = useGetCompaniesQuery();

  const users = isLoading ? customers : data;

  useEffect(() => {
    if (users && companies) {
      const newEnrichedUsers = users.map(user => {
        const company = companies.find(
          company => company.id === user.company_id
        );
        return {
          ...user,
          company: company ? company.name : null,
          companyLogo: company ? company.logo : null
        };
      });
      setEnrichedUsers(newEnrichedUsers);
    }
  }, [users, companies]);

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={enrichedUsers}
      selection={false}
      sortable
      pagination
      perPage={10}
    >
      <Card className="mb-3">
        <Card.Header>
          <UsersTableHeader table />
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTablePagination table />
        </Card.Footer>
      </Card>
      <UsersForm />
    </AdvanceTableWrapper>
  );
};

export default Users;
