import React from 'react';
import PropTypes from 'prop-types';
import KanbanColumnHeader from './KanbanColumnHeader';
import TaskCard from './TaskCard';
import classNames from 'classnames';
// import { KanbanContext } from 'context/Context';
import StrictModeDroppable from './StrictModeDroppable';
import { useAuth } from 'hooks/useAuth';

const KanbanColumn = ({ kanbanColumnItem }) => {
  const { id, name, orders } = kanbanColumnItem;
  const user = useAuth();

  const isAllowed = user.role === 'admin';

  return (
    <div className={classNames('kanban-column')}>
      <KanbanColumnHeader
        id={id}
        title={name}
        itemCount={orders.length}
        isAllowed={isAllowed}
      />
      <StrictModeDroppable droppableId={`${id}`} type="KANBAN">
        {provided => (
          <>
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              id={`container-${id}`}
              className="kanban-items-container scrollbar"
            >
              {orders.map((task, index) => (
                <TaskCard
                  key={task.id}
                  index={index}
                  task={task}
                  isAllowed={isAllowed}
                />
              ))}
              {/* {
                <AddAnotherForm
                  onSubmit={handleSubmit}
                  type="card"
                  showForm={showForm}
                  setShowForm={setShowForm}
                />
              }
              {provided.placeholder}
              <div ref={formViewRef}></div> */}
            </div>
            {/* {!showForm && (
              <div className="kanban-column-footer">
                <IconButton
                  size="sm"
                  variant="link"
                  className="d-block w-100 btn-add-card text-decoration-none text-600"
                  icon="plus"
                  iconClassName="me-2"
                  onClick={() => setShowForm(true)}
                >
                  Add another card
                </IconButton>
              </div>
            )} */}
          </>
        )}
      </StrictModeDroppable>
    </div>
  );
};

KanbanColumn.propTypes = {
  kanbanColumnItem: PropTypes.object
};

export default KanbanColumn;
