import React, { useState } from 'react';
import { Row, Col, Dropdown } from 'react-bootstrap';
import { members } from 'data/kanban';
import GroupMember from './GroupMember';
import InviteToBoard from './InviteToBoard';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useDeleteBoardMutation,
  useShowBoardQuery
} from 'services/boards.service';
import { useNavigate, useParams } from 'react-router-dom';
import DeleteConfirmation from 'components/common/DeleteConfirmation';
import { useCreateLaneMutation } from 'services/lanes.service';
import InlineForm from 'components/common/InlineForm';
import { useAuth } from 'hooks/useAuth';

const KanbanHeader = () => {
  const [show, setShow] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const user = useAuth();

  const { data: board, isLoading } = useShowBoardQuery(id);
  const [deleteBoard, { isLoading: isRemoving }] = useDeleteBoardMutation();

  const [createLane] = useCreateLaneMutation();

  const viewers = board?.viewers;
  const isAllowed = user.role === 'admin';

  const handleSubmit = name => {
    createLane({ board_id: board.id, name });
  };

  return (
    <Row className="gx-0 kanban-header rounded-2 px-x1 py-2 mt-2 mb-3">
      <Col className="d-flex align-items-center">
        <h5 className="mb-0">{board?.name}</h5>
        <div className="vertical-line vertical-line-400 position-relative h-100 mx-3"></div>
        <GroupMember
          avatarSize="l"
          users={isLoading ? members : viewers}
          showMember={4}
          className="d-none d-md-block"
          isAllowed={isAllowed}
        />
        <div className="vertical-line vertical-line-400 position-relative h-100 mx-3 d-none d-md-flex"></div>
        {user.role === 'admin' && <InviteToBoard />}
      </Col>
      {user.role === 'admin' && (
        <Col xs="auto" as={Flex} alignItems="center">
          <InlineForm
            placeholder="Nombre de la columna"
            msg="Añadir Columna"
            handleSubmit={handleSubmit}
          />
          <Dropdown align="end" className="font-sans-serif ms-2">
            <Dropdown.Toggle
              size="sm"
              variant="falcon-default"
              className="dropdown-caret-none"
            >
              <FontAwesomeIcon icon="ellipsis-h" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="border py-2">
              <Dropdown.Item
                href="#!"
                className="text-danger"
                onClick={() => setShow(true)}
              >
                Eliminar
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      )}
      <DeleteConfirmation
        msg={`¿Estás seguro de querer eliminar ${board?.name}?`}
        show={show}
        onHide={() => setShow(false)}
        handleDelete={() => {
          deleteBoard(board?.id);
          navigate('/');
        }}
        isLoading={isRemoving}
      />
    </Row>
  );
};

export default KanbanHeader;
