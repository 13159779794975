import AppContext from 'context/Context';
import { useOrderId } from 'hooks/useOrderId';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetBoardsQuery, useShowBoardQuery } from 'services/boards.service';
import { useUpdateOrderMutation } from 'services/orders.service';

const MoveCardForm = () => {
  const { id } = useParams();

  const [boardId, setBoardId] = useState(id);
  const [laneId, setLaneId] = useState('');

  const navigate = useNavigate();
  const orderId = useOrderId();
  const {
    config: { isDark }
  } = useContext(AppContext);

  const { data: boards } = useGetBoardsQuery();
  const { data: board } = useShowBoardQuery(boardId);
  const [updateOrder, { isLoading }] = useUpdateOrderMutation();

  const lanes = board?.lanes;

  useEffect(() => {
    if (lanes) {
      const currentLane = lanes.find(lane =>
        lane.orders.find(order => order.id === Number(orderId))
      );

      if (!currentLane) {
        setLaneId(lanes[0].id);
        return;
      }

      setLaneId(currentLane.id);
    }
  }, [lanes]);

  const handleMove = () => {
    updateOrder({ id: orderId, order: { lane_id: laneId } });
    navigate(`/board/${boardId}/${board.name}/order/${orderId}`);
  };

  if (!boards || !board) {
    return <Spinner />;
  }

  return (
    <Form>
      <Form.Group className="mb-2">
        <Form.Label>Tablero</Form.Label>
        <Form.Select
          value={boardId ?? ''}
          onChange={e => setBoardId(e.target.value)}
        >
          {boards?.map(board => (
            <option key={board.id} value={board.id}>
              {board.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group>
        <Form.Label>Columna</Form.Label>
        <Form.Select
          value={laneId ?? ''}
          onChange={e => setLaneId(e.target.value)}
        >
          {board.lanes.map(lane => (
            <option key={lane.id} value={lane.id}>
              {lane.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <DropdownItem
        as={Button}
        className={`w-100 mt-3 text-center btn-primary bg-primary ${
          isDark ? 'text-dark' : 'text-light'
        }`}
        size="sm"
        color="primary"
        variant="primary"
        onClick={handleMove}
      >
        {isLoading ? <Spinner size="sm" /> : 'Mover'}
      </DropdownItem>
    </Form>
  );
};

export default MoveCardForm;
