import React, { useState } from 'react';
import { Form, Col } from 'react-bootstrap';
import IconButton from './IconButton';
import PropTypes from 'prop-types';

const InlineForm = ({ placeholder, msg, handleSubmit }) => {
  const [show, setShow] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const toggle = () => setShow(!show);

  const onSubmit = e => {
    e.preventDefault();

    if (!inputValue) {
      return;
    }

    handleSubmit(inputValue);
    setInputValue('');
    toggle();
  };

  return (
    <Col>
      {show ? (
        <Form
          onSubmit={onSubmit}
          className="d-flex flex-column flex-md-row gap-2 mt-2 mt-md-0"
        >
          <Col xs={12} md="auto">
            <Form.Control
              type="text"
              placeholder={placeholder}
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
            />
          </Col>
          <Col
            xs={12}
            md="auto"
            className="d-flex justify-content-between gap-1"
          >
            <IconButton
              className="w-100"
              type="submit"
              icon="check"
              size="sm"
              variant="falcon-primary"
              transform="shrink-3"
            />
            <IconButton
              className="w-100"
              icon="times"
              size="sm"
              variant="falcon-danger"
              transform="shrink-3"
              onClick={toggle}
            />
          </Col>
        </Form>
      ) : (
        <IconButton
          icon="plus"
          transform="shrink-3"
          variant="falcon-default"
          size="sm"
          onClick={toggle}
        >
          {msg}
        </IconButton>
      )}
    </Col>
  );
};

InlineForm.propTypes = {
  placeholder: PropTypes.string,
  msg: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired
};

export default InlineForm;
