import React, { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { customersData } from 'data/ecommerce/customersData';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CustomersTableHeader from './CustomersTableHeader';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import {
  useDeleteCompanyMutation,
  useGetCompaniesQuery
} from 'services/companies.service';
import CustomersForm from './CustomersForm';
import { useDispatch } from 'react-redux';
import { setModal, setCompany } from 'slices/companies.slice';
import DeleteConfirmation from 'components/common/DeleteConfirmation';

const columns = [
  {
    accessor: 'name',
    Header: 'Nombre',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { name, logo } = rowData.row.original;
      return (
        <Link>
          <Flex alignItems="center">
            {logo ? (
              <Avatar src={logo} size="xl" className="me-2" />
            ) : (
              <Avatar size="xl" name={name} className="me-2" />
            )}
            <div className="flex-1">
              <h5
                className="mb-0 fs--1 text-truncate"
                style={{ maxWidth: '300px' }}
              >
                {name}
              </h5>
            </div>
          </Flex>
        </Link>
      );
    }
  },
  {
    accessor: 'rfc',
    Header: 'RFC'
    // Cell: rowData => {
    //   const { rfc } = rowData.row.original;
    //   return <a href='#'>{rfc}</a>;
    // }
  },
  {
    accessor: 'country',
    Header: 'País'
    // Cell: rowData => {
    //   const { phone } = rowData.row.original;
    //   return <a href={`tel:${phone}`}>{phone}</a>;
    // }
  },
  {
    accessor: 'state',
    Header: 'Estado'
    // Cell: rowData => {
    //   const { whatsapp } = rowData.row.original;
    //   return <a href={`tel:${whatsapp}`}>{whatsapp}</a>;
    // }
  },
  {
    accessor: 'city',
    Header: 'Ciudad'
  },
  {
    accessor: 'address',
    Header: 'Dirección',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { address } = rowData.row.original;
      return (
        <div
          className="text-truncate"
          style={{ maxWidth: '350px' }}
          title={address}
        >
          {address}
        </div>
      );
    }
  },
  {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    },
    Cell: rowData => {
      const dispatch = useDispatch();
      const [show, setShow] = useState(false);
      const [deleteCompany, { isLoading }] = useDeleteCompanyMutation();

      const company = rowData.row.original;

      return (
        <CardDropdown iconClassName="fs--1">
          <div className="py-2">
            <Dropdown.Item
              onClick={() => {
                dispatch(setCompany(company));
                dispatch(setModal(true));
              }}
            >
              Editar
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setShow(true);
              }}
            >
              Eliminar
            </Dropdown.Item>
          </div>
          <DeleteConfirmation
            msg={`¿Está seguro de querer eliminar a ${company.name}?`}
            show={show}
            onHide={() => setShow(false)}
            handleDelete={() => {
              deleteCompany(company.id);
              setShow(false);
            }}
            isLoading={isLoading}
          />
        </CardDropdown>
      );
    }
  }
];

const Customers = () => {
  const [customers] = useState(customersData);

  const { data, isLoading } = useGetCompaniesQuery();

  const companies = isLoading ? customers : data;

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={companies}
      selection={false}
      sortable
      pagination
      perPage={10}
    >
      <Card className="mb-3">
        <Card.Header>
          <CustomersTableHeader table />
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTablePagination table />
        </Card.Footer>
      </Card>
      <CustomersForm />
    </AdvanceTableWrapper>
  );
};

export default Customers;
