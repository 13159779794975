import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Avatar, { AvatarGroup } from 'components/common/Avatar';
import { Dropdown, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import { Link, useParams } from 'react-router-dom';
import AppContext from 'context/Context';
import { useDeleteViewerMutation } from 'services/viewers.service';
import { useShowBoardQuery } from 'services/boards.service';
import {
  useCreateReviewerMutation,
  useDeleteReviewerMutation
} from 'services/reviewers.service';
import UserDropdownList from './UserDropdownList';
import { useOrderId } from 'hooks/useOrderId';

const GroupMember = ({
  avatarSize = 'l',
  users,
  showMember = 4,
  addMember,
  className,
  isAllowed
}) => {
  const {
    config: { isRTL }
  } = useContext(AppContext);

  const orderId = useOrderId();

  const { id } = useParams();

  const { data } = useShowBoardQuery(id);
  const [createReviewer] = useCreateReviewerMutation();
  const [deleteReviewer] = useDeleteReviewerMutation();
  const [deleteViewer] = useDeleteViewerMutation();

  const viewers = data?.viewers;

  const handleAdd = id => {
    createReviewer({ order_id: orderId, user_id: id });
  };

  const handleRemove = id => {
    if (!addMember) {
      deleteViewer(id);
    } else {
      deleteReviewer(id);
    }
  };

  return (
    <div className={className}>
      <AvatarGroup>
        {users.slice(0, showMember).map((user, index) => (
          <Dropdown key={user.id}>
            <Dropdown.Toggle
              as={Nav.Link}
              className={classNames('dropdown-caret-none p-0', {
                'ms-n1': index > 0
              })}
            >
              {user.user?.avatar ? (
                <Avatar src={user.user.avatar} size={avatarSize} />
              ) : (
                <Avatar name={user.user?.name} size={avatarSize} />
              )}
            </Dropdown.Toggle>

            <Dropdown.Menu
              align={isRTL ? 'end' : 'start'}
              className="dropdown-md px-0 py-3"
            >
              <Flex alignItems="center" className="px-3 gap-2">
                <Link to={`/profile/${user?.user?.id}`}>
                  {user.user?.avatar ? (
                    <Avatar src={user.user.avatar} size={avatarSize} />
                  ) : (
                    <Avatar name={user.user?.name} size={avatarSize} />
                  )}
                </Link>
                <div className="flex-1">
                  <h6 className="mb-0">
                    <Link
                      to={`/profile/${user?.user?.id}`}
                      className="text-900"
                    >
                      {user.user?.name}
                    </Link>
                  </h6>
                  <p className="fs--2 mb-0 text-500">{user.user?.email}</p>
                </div>
              </Flex>
              <Dropdown.Divider />
              {isAllowed && (
                <Dropdown.Item
                  className="text-danger"
                  onClick={() => handleRemove(user.id)}
                >
                  Remover Usuario
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        ))}
        {(users.length > showMember || addMember) && isAllowed && (
          <Dropdown>
            <Dropdown.Toggle
              as={Nav.Link}
              className="dropdown-caret-none p-0 ms-n1"
            >
              {!addMember ? (
                <Avatar
                  name={`${users.length - showMember}+`}
                  size={avatarSize}
                  isExact
                  mediaClass="avatar-button"
                />
              ) : (
                <Avatar
                  icon="plus"
                  size={avatarSize}
                  mediaClass="avatar-button flex-center"
                />
              )}
            </Dropdown.Toggle>

            <Dropdown.Menu
              align={isRTL ? 'end' : 'start'}
              className="dropdown-md"
            >
              <h6 className="dropdown-header py-0 px-3 mb-0">
                {!addMember ? 'Miembros del tablero' : 'Selecciona un miembro'}
              </h6>
              <Dropdown.Divider />
              {!addMember ? (
                <Flex className="px-3">
                  {users.map(user => (
                    <Link className="text-900" key={user.id}>
                      <div className="position-relative">
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={`member-${user.id}`}>
                              {user.user?.name}
                            </Tooltip>
                          }
                        >
                          <div className="">
                            {user.user?.avatar ? (
                              <Avatar src={user.user.avatar} size="xl" />
                            ) : (
                              <Avatar name={user.user?.name} size="xl" />
                            )}
                          </div>
                        </OverlayTrigger>
                      </div>
                    </Link>
                  ))}
                </Flex>
              ) : (
                <UserDropdownList
                  viewers={viewers}
                  users={users}
                  handleAdd={handleAdd}
                  handleRemove={handleRemove}
                  avatarSize={avatarSize}
                />
              )}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </AvatarGroup>
    </div>
  );
};

GroupMember.propTypes = {
  avatarSize: PropTypes.string,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      img: PropTypes.string,
      role: PropTypes.string
    })
  ).isRequired,
  showMember: PropTypes.number,
  addMember: PropTypes.bool,
  className: PropTypes.string,
  isAllowed: PropTypes.bool
};

export default GroupMember;
