import api from './api';

export const attachmentsService = api.injectEndpoints({
  endpoints: builder => ({
    getAttachments: builder.query({
      query: () => 'attachments',
      providesTags: ['Attachments'],
      transformResponse: res => res.result
    }),
    showAttachment: builder.query({
      query: id => `attachments/${id}`
    }),
    createAttachment: builder.mutation({
      query: attachment => ({
        url: 'attachments',
        method: 'POST',
        body: attachment
      }),
      invalidatesTags: ['Attachments', 'Board', 'Order']
    }),
    updateAttachment: builder.mutation({
      query: ({ id, attachment }) => ({
        url: `attachments/${id}`,
        method: 'PUT',
        body: attachment
      }),
      invalidatesTags: ['Attachments', 'Board', 'Order']
    }),
    deleteAttachment: builder.mutation({
      query: id => ({
        url: `attachments/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Attachments', 'Board', 'Order']
    })
  })
});

export const {
  useGetAttachmentsQuery,
  useShowAttachmentQuery,
  useCreateAttachmentMutation,
  useUpdateAttachmentMutation,
  useDeleteAttachmentMutation
} = attachmentsService;
