import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import image from 'assets/img/kanban/4.jpg';
import AppContext from 'context/Context';

const Board = ({ imgSrc, bg, name, id }) => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  return (
    <Card bg={bg} text="white" style={{ maxWidth: '30rem' }}>
      <Card.Img
        src={image}
        style={{ opacity: '0.5', background: imgSrc ? '#FFFFFF' : '' }}
      />
      <Card.ImgOverlay className="d-flex align-items-center justify-content-center">
        <Link
          className="stretched-link text-decoration-none"
          to={`/board/${id}/${name}`}
        >
          <Card.Title className={`${isDark ? 'text-dark' : 'text-white'}`}>
            {name}
          </Card.Title>
        </Link>
      </Card.ImgOverlay>
    </Card>
  );
};

Board.propTypes = {
  imgSrc: PropTypes.string,
  bg: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.number
};

export default Board;
