const transformGroups = groups => {
  return groups?.reduce((acc, group) => {
    const existingOrder = acc.find(order => order.id === group.order.id);

    if (existingOrder) {
      existingOrder.groups.push(group);
    } else {
      acc.push({
        ...group.order,
        groups: [group]
      });
    }

    return acc;
  }, []);
};

export default transformGroups;
