import api from './api';

export const lanesService = api.injectEndpoints({
  endpoints: builder => ({
    getLanes: builder.query({
      query: () => 'lanes',
      providesTags: ['Lanes'],
      transformResponse: response => response.result
    }),
    showLane: builder.query({
      query: id => `lanes/${id}`
    }),
    createLane: builder.mutation({
      query: lane => ({
        url: 'lanes',
        method: 'POST',
        body: lane
      }),
      invalidatesTags: ['Lanes', 'Board']
    }),
    updateLane: builder.mutation({
      query: ({ id, lane }) => ({
        url: `lanes/${id}`,
        method: 'PUT',
        body: lane
      }),
      invalidatesTags: ['Lanes', 'Board']
    }),
    deleteLane: builder.mutation({
      query: id => ({
        url: `lanes/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Lanes', 'Board']
    })
  })
});

export const {
  useGetLanesQuery,
  useShowLaneQuery,
  useCreateLaneMutation,
  useUpdateLaneMutation,
  useDeleteLaneMutation
} = lanesService;
