import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { ToDoListItem } from 'components/dashboards/crm/ToDoList';
import React, { useEffect, useState } from 'react';
import { Button, Col, Dropdown, Form, ProgressBar, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useCreateTaskMutation } from 'services/tasks.service';
import PropTypes from 'prop-types';
import ModalMediaContent from './ModalMediaContent';
import {
  useDeleteGroupMutation,
  useUpdateGroupMutation
} from 'services/group.service';
import { isIterableArray } from 'helpers/utils';

const ModalChecklistContent = ({ group, isAllowed }) => {
  const [show, setShow] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [dueDate, setDueDate] = useState(null);
  const [description, setDescription] = useState('');
  const [tasks, setTasks] = useState(group.tasks);
  const [showCompleted, setShowCompleted] = useState(false);

  useEffect(() => {
    setTasks(group.tasks);
  }, [group.tasks]);

  const completedTasks = group.tasks.filter(task => task.done).length;

  const completedPercentage = (completedTasks / group.tasks.length) * 100;

  const [createTask] = useCreateTaskMutation();
  const [updateGroup] = useUpdateGroupMutation();
  const [deleteGroup] = useDeleteGroupMutation();

  const toggle = () => setShow(!show);

  const handleSubmit = e => {
    e.preventDefault();
    if (!description) return;

    createTask({
      description: description,
      due_date: dueDate,
      done: 0,
      group_id: group.id
    });

    setDescription('');
    setDueDate(null);
  };

  const handleDelete = () => deleteGroup(group.id);

  const handleRename = name => updateGroup({ id: group.id, group: { name } });

  return (
    <ModalMediaContent
      title={group.name}
      icon="check"
      edit
      handleSubmit={handleRename}
      headingClass="justify-content-between align-items-center"
      headingContent={
        <Flex>
          <Button
            className="me-1 fs--1"
            variant="falcon-default"
            size="sm"
            onClick={() => {
              setShowCompleted(!showCompleted);
              if (!showCompleted) {
                setTasks(prevTasks => prevTasks.filter(task => !task.done));
              } else {
                setTasks(group.tasks);
              }
            }}
          >
            {showCompleted
              ? `Mostrar tareas marcadas (${completedTasks})`
              : 'Ocultar tareas marcadas'}
          </Button>

          {isAllowed && (
            <Dropdown>
              <Dropdown.Toggle
                className="dropdown-caret-none border-0"
                variant="falcon-default"
                size="sm"
              >
                <FontAwesomeIcon icon="trash" size="sm" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-md">
                <Dropdown.Header>¿Eliminar {group.name}?</Dropdown.Header>
                <Dropdown.Divider />
                <Dropdown.ItemText>
                  <p>
                    Si eliminas este grupo, las tareas también serán eliminadas
                  </p>
                  <Button
                    variant="danger"
                    className="w-100"
                    size="sm"
                    onClick={handleDelete}
                  >
                    Eliminar
                  </Button>
                </Dropdown.ItemText>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </Flex>
      }
    >
      {isIterableArray(group.tasks) && (
        <Row className="gx-3 align-items-center my-3 px-x1">
          <Col xs="auto">
            <h6 className="text-primary mb-0">
              {completedPercentage.toFixed(0)}%
            </h6>
          </Col>
          <Col>
            <ProgressBar style={{ height: '0.5625rem' }}>
              <ProgressBar
                now={completedPercentage}
                key={1}
                variant="progress-gradient"
                className="rounded-pill"
              />
            </ProgressBar>
          </Col>
        </Row>
      )}
      {tasks.map(task => (
        <ToDoListItem
          key={task.id}
          item={task}
          index={task.id}
          isAllowed={isAllowed}
        />
      ))}
      {showForm ? (
        <div className="position-relative border rounded mb-3">
          <Form onSubmit={handleSubmit}>
            <Form.Control
              as="textarea"
              className="border-0 rounder-bottom-0 resize-none"
              rows={2}
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
            <Flex
              justifyContent="between"
              alignItems="center"
              className="bg-light rounded-bottom p-2 mt-1"
            >
              <div>
                <Button className="me-1" size="sm" type="submit">
                  Añadir
                </Button>
                <Button
                  className="border-0"
                  variant="outline-secondary"
                  size="sm"
                  color="secondary"
                  onClick={() => setShowForm(null)}
                >
                  Cancelar
                </Button>
              </div>
              <Dropdown show={show} onToggle={toggle}>
                <Dropdown.Toggle
                  className="dropdown-caret-none border-0"
                  variant="outline-secondary"
                  size="sm"
                >
                  <FontAwesomeIcon icon="clock" size="sm" className="me-1" />
                  {dueDate
                    ? dueDate.toLocaleDateString('es-MX', {
                        year: '2-digit',
                        month: 'short',
                        day: 'numeric'
                      })
                    : 'Fecha Límite'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.ItemText>
                    <DatePicker
                      selected={dueDate}
                      onChange={date => {
                        setDueDate(date);
                        toggle();
                      }}
                      inline
                    />
                  </Dropdown.ItemText>
                </Dropdown.Menu>
              </Dropdown>
            </Flex>
          </Form>
        </div>
      ) : (
        isAllowed && (
          <Button
            variant="falcon-default"
            size="sm"
            onClick={() => setShowForm(true)}
          >
            Añadir tarea
          </Button>
        )
      )}
    </ModalMediaContent>
  );
};

ModalChecklistContent.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    tasks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired
      })
    ).isRequired
  }).isRequired,
  isAllowed: PropTypes.bool
};

export default ModalChecklistContent;
