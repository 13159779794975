import { createSlice } from '@reduxjs/toolkit';
import { authenticationService } from 'services/authentication.service';
import { usersService } from 'services/users.service';

const initialState = {
  currentUser: JSON.parse(localStorage.getItem('currentUser')) || null,
  token: null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout(state) {
      state.currentUser = null;
      localStorage.removeItem('currentUser');
    },
    setCurrentUser(state, action) {
      const token = state.currentUser.token;

      const user = { ...action.payload, token };

      localStorage.setItem('currentUser', JSON.stringify(user));
      state.currentUser = user;
    },
    setToken(state, action) {
      state.token = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addMatcher(
      authenticationService.endpoints.authenticate.matchFulfilled,
      (state, action) => {
        const user = action.payload;

        localStorage.setItem('currentUser', JSON.stringify(user));
        state.currentUser = user;
      }
    );
    builder.addMatcher(
      usersService.endpoints.updateCurrentUser.matchFulfilled,
      (state, action) => {
        const token = state.currentUser.token;

        const user = { ...action.payload.result, token };

        localStorage.setItem('currentUser', JSON.stringify(user));
        state.currentUser = user;
      }
    );
  }
});

export const { loginSuccess, logout, setCurrentUser, setToken } =
  authSlice.actions;
export const selectCurrentUser = state => state.auth.currentUser;

export default authSlice.reducer;
