import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form, Spinner } from 'react-bootstrap';
import classNames from 'classnames';
import { useResetPasswordMutation } from 'services/authentication.service';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const PasswordResetForm = ({ hasLabel }) => {
  // State
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: ''
  });

  const { token } = useSelector(state => state.auth);

  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const navigate = useNavigate();

  // Handler
  const handleSubmit = async e => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      toast.error('Los campos deben coincidir', {
        theme: 'colored',
        position: 'top-center'
      });
      return;
    }

    if (token) {
      try {
        console.log(token, formData.password);
        const result = await resetPassword({
          token,
          password: formData.password
        }).unwrap();
        console.log(result);
        toast.success('Inicia sesión con tu nueva contraseña', {
          theme: 'colored'
        });
        navigate('/authentication');
      } catch (error) {
        console.error(error);
        toast.error('Ha ocurrido un error inesperado, intentálo de nuevo', {
          theme: 'colored',
          position: 'top-center'
        });
      }
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form
      className={classNames('mt-3', { 'text-left': hasLabel })}
      onSubmit={handleSubmit}
    >
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Nueva Contraseña</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Nueva Contraseña' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Confirmar Contraseña</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Confirmar Contraseña' : ''}
          value={formData.confirmPassword}
          name="confirmPassword"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Button
        type="submit"
        className="w-100"
        disabled={!formData.password || !formData.confirmPassword}
      >
        {isLoading ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          'Reestablecer Contraseña'
        )}
      </Button>
    </Form>
  );
};

PasswordResetForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default PasswordResetForm;
