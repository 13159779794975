import FalconCardHeader from 'components/common/FalconCardHeader';
import React from 'react';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useModifyPasswordMutation } from 'services/users.service';
import { toast } from 'react-toastify';

const validationSchema = Yup.object().shape({
  password: Yup.string().required('Required'),
  newPassword: Yup.string().required('Required'),
  confirmPassword: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
});

const ChangePassword = () => {
  const [modifyPassword, { isLoading }] = useModifyPasswordMutation();

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const { errors, isDirty, isValid } = formState;

  const onSubmit = async values => {
    try {
      await modifyPassword({
        password: values.password,
        newPassword: values.newPassword
      }).unwrap();
      toast.success('Contraseña modificada exitosamente', {
        position: 'top-right'
      });
    } catch (error) {
      if (error.status === 500) {
        toast.error('Ha ocurrido un error inesperado', {
          position: 'top-right'
        });
        return;
      }

      toast.error(error.data.result, { position: 'top-right' });
    }
  };

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Cambiar Contraseña" />
      <Card.Body className="bg-light">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3" controlId="password">
            <Form.Label>Contraseña Actual</Form.Label>
            <Form.Control
              type="password"
              {...register('password')}
              isInvalid={errors.password}
            />
            {errors.password && (
              <div className="text-danger">{errors.password.message}</div>
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="newPassword">
            <Form.Label>Nueva Contraseña</Form.Label>
            <Form.Control
              type="password"
              {...register('newPassword')}
              isInvalid={errors.newPassword}
            />
            {errors.newPassword && (
              <div className="text-danger">{errors.newPassword.message}</div>
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="confirmPassword">
            <Form.Label>Confirmar Contraseña</Form.Label>
            <Form.Control
              type="password"
              {...register('confirmPassword')}
              isInvalid={errors.confirmPassword}
            />
            {errors.confirmPassword && (
              <div className="text-danger">
                {errors.confirmPassword.message}
              </div>
            )}
          </Form.Group>
          <Button
            className="w-100"
            type="submit"
            disabled={!isDirty || !isValid}
          >
            {isLoading ? <Spinner size="sm" /> : 'Cambiar Contraseña'}
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ChangePassword;
