import React, { useContext, useMemo } from 'react';
import { Alert, Card, Col, Row } from 'react-bootstrap';
import {
  useCreateBoardMutation,
  useGetBoardsQuery
} from 'services/boards.service';
import Board from './Board';
import { isIterableArray } from 'helpers/utils';
import AppContext from 'context/Context';
import FalconCardHeader from 'components/common/FalconCardHeader';
import InlineForm from 'components/common/InlineForm';
import { useAuth } from 'hooks/useAuth';
import { useGetViewersQuery } from 'services/viewers.service';
import { useEffect } from 'react';

const Boards = () => {
  const { data } = useGetBoardsQuery();
  const { data: viewers } = useGetViewersQuery();
  const [createBoard] = useCreateBoardMutation();

  const user = useAuth();

  const {
    config: { isDark },
    setConfig
  } = useContext(AppContext);

  const bg = isDark ? 'white' : 'dark';

  useEffect(() => {
    setConfig('isFluid', false);
  }, []);

  const boards = useMemo(() => {
    if (user.role === 'admin') {
      return data;
    }

    const viewersUser = viewers?.filter(viewer => viewer.user_id === user.id);
    const boardIds = new Set(viewersUser?.map(viewer => viewer.board_id));

    return data?.filter(board => boardIds.has(board.id));
  }, [user, data, viewers]);

  const handleSubmit = value => {
    createBoard({ name: value });
  };

  return (
    <Card>
      <FalconCardHeader
        title="Tableros"
        endEl={
          user.role === 'admin' && (
            <InlineForm
              placeholder="Nombre del tablero"
              handleSubmit={handleSubmit}
            />
          )
        }
      />
      <Card.Body className="fs-1">
        {isIterableArray(boards) ? (
          <Row>
            {boards.map(board => (
              <Col key={board.id} sm={6} md={4} className="mb-3">
                <Board bg={bg} {...board} />
              </Col>
            ))}
          </Row>
        ) : (
          <Alert color="info" className="mb-0">
            No se encontraron tableros
          </Alert>
        )}
      </Card.Body>
    </Card>
  );
};

export default Boards;
