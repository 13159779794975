import AppContext from 'context/Context';
import { useOrderId } from 'hooks/useOrderId';
import React, { useEffect, useMemo, useState, useContext } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { useParams } from 'react-router-dom';
import { useGetBoardsQuery, useShowBoardQuery } from 'services/boards.service';
import {
  useDuplicateOrderMutation,
  useShowOrderQuery
} from 'services/orders.service';

const CopyCardForm = () => {
  const { id } = useParams();

  const [boardId, setBoardId] = useState(id);
  const [laneId, setLaneId] = useState('');
  const [name, setName] = useState('');
  const [checkedItems, setCheckedItems] = useState({});

  const orderId = useOrderId();
  const {
    config: { isDark }
  } = useContext(AppContext);

  const { data: boards } = useGetBoardsQuery();
  const { data: board } = useShowBoardQuery(boardId);
  const { data: order } = useShowOrderQuery(orderId);
  const [duplicateOrder, { isLoading }] = useDuplicateOrderMutation();

  const lanes = board?.lanes;

  useEffect(() => {
    if (lanes) {
      const currentLane = lanes.find(lane =>
        lane.orders.find(order => order.id === Number(orderId))
      );

      if (!currentLane) {
        setLaneId(lanes[0].id);
        return;
      }

      setLaneId(currentLane.id);
    }
  }, [lanes]);

  useEffect(() => {
    if (order) {
      setName(order.name);
    }
  }, [order]);

  const elements = useMemo(() => {
    const { description, groups, messages, tags, attachments, reviewers } =
      order;

    return [
      { id: 'description', name: 'Descripción', data: description },
      { id: 'groups', name: 'Lista de tareas', data: groups },
      { id: 'comments', name: 'Comentarios', data: messages },
      { id: 'tags', name: 'Etiquetas', data: tags },
      { id: 'attachments', name: 'Archivos adjuntos', data: attachments },
      { id: 'reviewers', name: 'Revisores', data: reviewers }
    ];
  }, [order]);

  useEffect(() => {
    if (elements.length > 0) {
      const newCheckedItems = elements.reduce((acc, element) => {
        acc[element.id] = true;
        return acc;
      }, {});
      setCheckedItems(newCheckedItems);
    }
  }, [elements]);

  // Update the checkedItems state when a checkbox is toggled
  const handleCheckboxChange = id => {
    setCheckedItems(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  const handleCopy = () => {
    duplicateOrder({
      id: orderId,
      lane: laneId,
      body: { name, ...checkedItems }
    });
  };

  if (!boards || !board || !order) {
    return <Spinner />;
  }

  return (
    <Form>
      <Form.Group className="mb-2">
        <Form.Label htmlFor="title">Título</Form.Label>
        <Form.Control
          name="title"
          id="title"
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Copiar con</Form.Label>
        {elements.map(element => {
          const display =
            element.id === 'description'
              ? element.data
              : element.data.length !== 0;

          const label =
            element.id === 'description'
              ? element.name
              : `${element.name} (${element.data.length})`;

          return (
            display && (
              <Form.Check
                key={element.id}
                type="checkbox"
                id={element.id}
                label={label}
                checked={checkedItems[element.id]}
                onChange={() => handleCheckboxChange(element.id)}
              />
            )
          );
        })}
      </Form.Group>
      <Form.Group>
        <Form.Label>Copiar a</Form.Label>
        <Form.Select
          value={boardId ?? ''}
          onChange={e => setBoardId(e.target.value)}
        >
          {boards?.map(board => (
            <option key={board.id} value={board.id}>
              {board.name}
            </option>
          ))}
        </Form.Select>
        <Form.Select
          value={laneId ?? ''}
          onChange={e => setLaneId(e.target.value)}
        >
          {board.lanes.map(lane => (
            <option key={lane.id} value={lane.id}>
              {lane.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <DropdownItem
        as={Button}
        className={`w-100 mt-3 text-center btn-primary bg-primary ${
          isDark ? 'text-dark' : 'text-light'
        }`}
        size="sm"
        color="primary"
        variant="primary"
        onClick={handleCopy}
      >
        {isLoading ? <Spinner size="sm" /> : 'Crear Orden'}
      </DropdownItem>
    </Form>
  );
};

export default CopyCardForm;
