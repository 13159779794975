import React from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

const DeleteConfirmation = ({ msg, show, onHide, handleDelete, isLoading }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmar Eliminación</Modal.Title>
      </Modal.Header>
      <Modal.Body>{msg}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          {isLoading ? <Spinner size="sm" /> : 'Eliminar'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DeleteConfirmation.propTypes = {
  msg: PropTypes.string,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  handleDelete: PropTypes.func,
  isLoading: PropTypes.bool
};

export default DeleteConfirmation;
