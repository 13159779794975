import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import { useDispatch } from 'react-redux';
import { logout } from 'slices/authentication.slice';
import { useAuth } from 'hooks/useAuth';

const ProfileDropdown = () => {
  const dispatch = useDispatch();
  const user = useAuth();

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        {user.avatar ? (
          <Avatar src={user.avatar} />
        ) : (
          <Avatar name={user.name} />
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item as={Link} to={`/profile/${user.id}`}>
            Perfil
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/settings">
            Ajustes
          </Dropdown.Item>
          <Dropdown.Item
            as={Link}
            to="/authentication"
            onClick={() => dispatch(logout())}
          >
            Cerrar Sesión
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
