import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  ProgressBar,
  Row
} from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { toDoList } from 'data/dashboard/crm';
import CardDropdown from 'components/common/CardDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import {
  useDeleteTaskMutation,
  useUpdateTaskMutation
} from 'services/tasks.service';

const today = new Date();

export const ToDoListItem = ({ item, index, isAllowed }) => {
  const [show, setShow] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [description, setDescription] = useState(item.description);

  const [updateTask] = useUpdateTaskMutation();
  const [deleteTask] = useDeleteTaskMutation();

  const dueDate = item.due_date ? new Date(item.due_date) : null;

  const yesterday = new Date(dueDate);
  yesterday.setDate(yesterday.getDate() - 1);

  const isDueTomorrow = yesterday <= today && dueDate > today;
  const variant =
    dueDate && dueDate <= today
      ? 'outline-danger'
      : isDueTomorrow
      ? 'outline-warning'
      : 'outline-secondary';

  const toggle = () => setShow(!show);

  const handleSubmit = e => {
    e.preventDefault();
    updateTask({ id: item.id, task: { description } });
    setDescription('');
    setShowInput(false);
  };

  const handleChange = checked => {
    if (!isAllowed) return;

    const done = checked ? 1 : 0;

    updateTask({ id: item.id, task: { done } });
  };

  const handleChangeDate = date => {
    updateTask({ id: item.id, task: { due_date: date } });
  };

  const handleDelete = () => deleteTask(item.id);

  return (
    <Flex
      justifyContent="between"
      className="hover-actions-trigger btn-reveal-trigger px-x1 hover-bg-200"
      alignItems="center"
    >
      <Form onSubmit={handleSubmit}>
        <Form.Check
          className="py-3 mb-0 d-flex align-items-center"
          style={{ height: '2.5rem' }}
          id={`todoItem-${index}`}
        >
          <Form.Check.Input
            type="checkbox"
            defaultChecked={item.done}
            onChange={e => handleChange(e.target.checked)}
            className={classNames('rounded-3 mt-0', {
              'form-check-line-through': item.done
            })}
          />
          {showInput ? (
            <Form.Control
              className="ms-3"
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          ) : (
            <Form.Check.Label className="flex-1 mb-0 ms-3">
              {item.description}
            </Form.Check.Label>
          )}
        </Form.Check>
      </Form>
      {isAllowed && (
        <>
          <div className="d-flex align-items-center">
            <div className="hover-actions">
              <Dropdown show={show} onToggle={toggle}>
                <Dropdown.Toggle
                  className="dropdown-caret-none border-0"
                  variant={variant}
                  size="sm"
                >
                  <FontAwesomeIcon
                    icon="clock"
                    size="sm"
                    className={dueDate ? 'me-1' : ''}
                  />
                  {dueDate &&
                    dueDate.toLocaleDateString('es-MX', {
                      year: '2-digit',
                      month: 'short',
                      day: 'numeric'
                    })}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.ItemText>
                    <DatePicker
                      selected={dueDate}
                      minDate={today}
                      onChange={date => {
                        handleChangeDate(date);
                        toggle();
                      }}
                      inline
                    />
                  </Dropdown.ItemText>
                </Dropdown.Menu>
              </Dropdown>
              {/* <IconButton
            variant="outline-secondary"
            icon="user-plus"
            className="border-0 rounded-3 me-2 fs--2 hover-bg-100"
          /> */}
            </div>
          </div>
          <div>
            <CardDropdown
              btnRevealClass="btn-reveal-sm "
              drop={index > 1 ? 'start' : undefined}
              onShow={() => setShowInput(!showInput)}
              handleDelete={handleDelete}
            />
          </div>
        </>
      )}
    </Flex>
  );
};

const ToDoList = ({ isAllowed }) => {
  const [todoItems, setTodoItems] = useState(toDoList);
  const handleChange = (id, completed) => {
    const updatedTodos = todoItems.map(item =>
      item.id === id ? { ...item, completed } : item
    );
    setTodoItems(updatedTodos);
  };
  return (
    <Card className="h-100">
      <Card.Header className="border-bottom">
        <h6 className="mb-0">To Do List</h6>
      </Card.Header>
      <Card.Body className="p-0 overflow-hidden">
        <Row className="gx-3 align-items-center my-3 px-x1">
          <Col xs="auto">
            <h6 className="text-primary mb-0">25%</h6>
          </Col>
          <Col>
            <ProgressBar style={{ height: '0.5625rem' }}>
              <ProgressBar
                now={70}
                key={1}
                variant="progress-gradient"
                className="rounded-pill"
              />
            </ProgressBar>
          </Col>
        </Row>
        {todoItems.map((item, index) => (
          <ToDoListItem
            key={item.id}
            item={item}
            index={index}
            setTodoItems={setTodoItems}
            handleChange={handleChange}
            isAllowed={isAllowed}
          />
        ))}
      </Card.Body>
      <Card.Footer className="p-0 bg-light">
        <Button
          as={Link}
          variant="link"
          size="sm"
          to="#!"
          className="w-100 py-2"
        >
          <FontAwesomeIcon icon="plus" className="me-1 fs--2" />
          Add New Task
        </Button>
      </Card.Footer>
    </Card>
  );
};

ToDoListItem.propTypes = {
  item: PropTypes.shape({
    description: PropTypes.string.isRequired,
    done: PropTypes.bool.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    due_date: PropTypes.string
  }),
  index: PropTypes.number.isRequired,
  isAllowed: PropTypes.bool
};

ToDoList.propTypes = {
  isAllowed: PropTypes.bool
};

export default ToDoList;
