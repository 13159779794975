import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import Background from 'components/common/Background';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, CloseButton, Form } from 'react-bootstrap';
import FalconLightBoxGallery from 'components/common/FalconLightBoxGallery';
import { useShowOrderQuery } from 'services/orders.service';
import { formatDate } from '@fullcalendar/react';
import {
  useDeleteAttachmentMutation,
  useUpdateAttachmentMutation
} from 'services/attachment.service';
import { useOrderId } from 'hooks/useOrderId';

// Array of image extensions
const imageExtensions = [
  'jpg',
  'jpeg',
  'png',
  'webp',
  'bmp',
  'gif',
  'tiff',
  'tif',
  'ico',
  'jfif',
  'svg',
  'heif',
  'heic'
];

const Title = ({ title, show, onSubmit }) => {
  const [name, setName] = useState(title);

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit(name);
  };

  const icon = <FontAwesomeIcon icon="download" />;

  return show ? (
    <Form onSubmit={handleSubmit}>
      <Form.Control
        value={name}
        onChange={e => setName(e.target.value)}
        onClick={e => e.stopPropagation()}
      />
    </Form>
  ) : (
    <p>
      {title} {icon}
    </p>
  );
};

const AttachmentItem = ({
  setImgIndex,
  attachment,
  isLast,
  index,
  isAllowed
}) => {
  const [nestedModal, setNestedModal] = useState(false);
  const [show, setShow] = useState(false);

  const [updateAttachment] = useUpdateAttachmentMutation();
  const [deleteAttachment] = useDeleteAttachmentMutation();

  const isPdf = attachment.archive_type === 'pdf';

  const handleDownload = async () => {
    try {
      // Fetch the PDF file
      const response = await fetch(attachment.src);
      if (!response.ok) throw new Error('Network response was not ok');

      // Create a blob from the response data
      const blob = await response.blob();
      // Create an object URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a new anchor element
      const link = document.createElement('a');
      link.href = url;
      link.download = attachment.title || 'download.pdf'; // Provide a default filename if necessary
      document.body.appendChild(link);
      link.click();

      // Clean up by revoking the object URL and removing the link
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error during file download:', error);
    }
  };

  const handleSubmit = title => {
    updateAttachment({ id: attachment.id, attachment: { title } });
    setShow(false);
  };

  return (
    <>
      <Flex alignItems="center" className={classNames({ 'mb-3': !isLast })}>
        <div className="bg-attachment me-3">
          {imageExtensions.includes(attachment.archive_type) ? (
            <>
              {attachment.archive_type !== 'mp4' ? (
                <div
                  className="cursor-pointer"
                  onClick={() => setImgIndex(index)}
                >
                  <Background image={attachment.src} rounded />
                </div>
              ) : (
                <>
                  <Link to="#!" onClick={() => setNestedModal(true)}>
                    <Background image={attachment.src} className="rounded" />
                    <div className="icon-play">
                      <FontAwesomeIcon icon="play" />
                    </div>
                  </Link>
                </>
              )}
            </>
          ) : isPdf ? (
            <div className="cursor-pointer">
              <FontAwesomeIcon icon="file-pdf" size="2x" />
            </div>
          ) : (
            <span className="text-uppercase fw-bold">
              {attachment.archive_type}
            </span>
          )}
        </div>

        <div className="flex-1 fs--2">
          <h6 className="mb-1">
            {imageExtensions.includes(attachment.archive_type) ? (
              <>
                {attachment.archive_type !== 'mp4' ? (
                  <Link
                    onClick={handleDownload}
                    to="#!"
                    className="text-decoration-none"
                  >
                    <Title
                      title={attachment.title}
                      show={show}
                      onSubmit={handleSubmit}
                    />
                  </Link>
                ) : (
                  <>
                    <Link to="#!" onClick={() => setNestedModal(true)}>
                      <Title
                        title={attachment.title}
                        show={show}
                        onSubmit={handleSubmit}
                      />
                    </Link>
                  </>
                )}
              </>
            ) : (
              <Link
                to="#!"
                className="text-decoration-none"
                onClick={handleDownload}
              >
                <Title
                  title={attachment.title}
                  show={show}
                  onSubmit={handleSubmit}
                />
              </Link>
            )}
          </h6>
          {isAllowed && (
            <>
              <Link
                to="#!"
                className="link-600 fw-semi-bold"
                onClick={() => setShow(true)}
              >
                Editar
              </Link>
              <span className="mx-1">|</span>
              <Link
                to="#!"
                className="link-600 fw-semi-bold"
                onClick={() => deleteAttachment(attachment.id)}
              >
                Remover
              </Link>
            </>
          )}
          <p className="mb-0">
            Creado el{' '}
            {formatDate(attachment.created_at, {
              month: 'long',
              year: 'numeric',
              day: 'numeric',
              locale: 'es'
            })}
          </p>
        </div>
      </Flex>

      <Modal
        show={nestedModal}
        size="xl"
        onHide={() => setNestedModal(false)}
        className="video-popup"
        contentClassName="bg-transparent border-0"
        backdropClassName="video-popup-backdrop"
        centered
      >
        <CloseButton
          variant="white"
          onClick={() => setNestedModal(false)}
          className="video-popup-close-btn"
        />
        <Modal.Body className="p-0 rounded overflow-hidden">
          <video
            poster={attachment.image}
            className="d-block"
            width="100%"
            height="100%"
            controls
            autoPlay
          >
            <source src={attachment.src} type="video/mp4" />
          </video>
        </Modal.Body>
      </Modal>
    </>
  );
};

const ModalAttachmentContent = ({ isAllowed }) => {
  const orderId = useOrderId();

  const { data: order } = useShowOrderQuery(orderId);

  const attachments = order?.attachments;

  const images = useMemo(() => {
    if (attachments) {
      return attachments
        .filter(attachment => imageExtensions.includes(attachment.archive_type))
        .map(attachment => attachment.src);
    }
  }, [attachments]);

  return (
    <>
      <FalconLightBoxGallery images={images}>
        {setImgIndex => (
          <>
            {attachments &&
              attachments.map((attachment, index) => (
                <AttachmentItem
                  setImgIndex={setImgIndex}
                  key={attachment.id}
                  attachment={attachment}
                  index={index}
                  isLast={attachments.length - 1 === index}
                  isAllowed={isAllowed}
                />
              ))}
          </>
        )}
      </FalconLightBoxGallery>
    </>
  );
};

Title.propTypes = {
  title: PropTypes.string,
  show: PropTypes.bool,
  onSubmit: PropTypes.func
};

AttachmentItem.propTypes = {
  attachment: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    image: PropTypes.string,
    src: PropTypes.string,
    title: PropTypes.string,
    date: PropTypes.string,
    type: PropTypes.string,
    archive_type: PropTypes.string, // Add this line
    created_at: PropTypes.string // Add this line
  }),
  isLast: PropTypes.bool,
  setImgIndex: PropTypes.func,
  index: PropTypes.number,
  isAllowed: PropTypes.bool
};

ModalAttachmentContent.propTypes = {
  isAllowed: PropTypes.bool
};

export default ModalAttachmentContent;
