import React, { useState } from 'react';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import { Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import { useOrderId } from 'hooks/useOrderId';
import { useShowOrderQuery } from 'services/orders.service';
import UserAvatar from 'components/common/UserAvatar';
import { useGetUsersQuery } from 'services/users.service';
import {
  useCreateMessageMutation,
  useDeleteMessageMutation,
  useUpdateMessageMutation
} from 'services/messages.service';
import PropTypes from 'prop-types';

const PostComment = ({ id, comment, setShow }) => {
  const [content, setContent] = useState(comment ?? '');

  const orderId = useOrderId();
  const user = useAuth();

  const [createMessage] = useCreateMessageMutation();
  const [updateMessage] = useUpdateMessageMutation();

  const handleSubmit = e => {
    e.preventDefault();

    if (!content) return;

    if (id) {
      updateMessage({ id, message: { content } });
      setShow('');
    } else {
      createMessage({ content, order_id: orderId });
    }

    setContent('');
  };

  return (
    <Flex>
      {user?.avatar ? (
        <Avatar src={user.avatar} className="me-2" size="l" />
      ) : (
        <Avatar name={user?.name} className="me-2" size="l" />
      )}
      <div className="flex-1 fs--1">
        <div className="position-relative border rounded mb-3">
          <Form onSubmit={handleSubmit}>
            <Form.Control
              as="textarea"
              className="border-0 rounded-bottom-0 resize-none"
              rows={3}
              value={content}
              onChange={e => setContent(e.target.value)}
            />
            <Flex
              justifyContent="between"
              alignItems="center"
              className="bg-light rounded-bottom p-2 mt-1"
            >
              <Flex className="gap-2">
                <Button size="sm" type="submit">
                  {id ? 'Guardar' : 'Comentar'}
                </Button>
                {id && (
                  <Button
                    size="sm"
                    variant="secondary"
                    onClick={() => setShow('')}
                  >
                    Cancelar
                  </Button>
                )}
              </Flex>
              {/* <ul className="list-inline mb-0">
                <li className="list-inline-item mr-1">
                  <Form.Group controlId="file-attachment">
                    <Form.Label className="link-600 px-2 transition-base rounded cursor-pointer mb-0">
                      <FontAwesomeIcon icon="paperclip" />
                    </Form.Label>
                    <Form.Control type="file" className="d-none" />
                  </Form.Group>
                </li>
                <li className="list-inline-item mr-1">
                  <Link
                    to="#!"
                    className="link-600 px-2 transition-base rounded"
                  >
                    <FontAwesomeIcon icon="at" />
                  </Link>
                </li>
                <li className="list-inline-item mr-1">
                  <Form.Group controlId="file-image">
                    <Form.Label className="link-600 px-2 transition-base rounded cursor-pointer mb-0">
                      <FontAwesomeIcon icon="image" />
                    </Form.Label>
                    <Form.Control
                      type="file"
                      className="d-none"
                      accept="image/*"
                    />
                  </Form.Group>
                </li>
              </ul> */}
            </Flex>
          </Form>
        </div>
      </div>
    </Flex>
  );
};

const ModalCommentContent = ({ isAllowed }) => {
  const [show, setShow] = useState('');

  const user = useAuth();
  const orderId = useOrderId();

  const { data: users } = useGetUsersQuery();
  const { data: order } = useShowOrderQuery(orderId);

  const [deleteMessage] = useDeleteMessageMutation();

  const comments = order?.messages;

  const getName = id => {
    const user = users?.find(user => user.id === id);

    return user?.name;
  };

  const handleDelete = id => deleteMessage(id);

  return (
    <>
      {isAllowed && <PostComment />}

      {comments
        ?.slice()
        .reverse()
        .map(comment =>
          comment.id === show ? (
            <PostComment
              key={comment.id}
              id={comment.id}
              comment={comment.content}
              setShow={setShow}
            />
          ) : (
            <Flex key={comment.id} className="mb-3">
              <Link to="#">
                {users && <UserAvatar id={comment.user_id} users={users} />}
              </Link>
              <div className="flex-1 ms-2 fs--1">
                <p className="mb-1 bg-200 rounded-3 p-2">
                  <Link to="#" className="fw-semi-bold">
                    {getName(comment.user_id)}
                  </Link>{' '}
                  {comment.content}
                </p>
                {user.id === comment.user_id && (
                  <>
                    <Link to="#!" onClick={() => setShow(comment.id)}>
                      {' '}
                      Editar{' '}
                    </Link>{' '}
                    &bull;
                  </>
                )}
                {(user.id === comment.user_id || user.role === 'admin') && (
                  <Link to="#!" onClick={() => handleDelete(comment.id)}>
                    {' '}
                    Eliminar{' '}
                  </Link>
                )}
              </div>
            </Flex>
          )
        )}
    </>
  );
};

PostComment.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  comment: PropTypes.string,
  setShow: PropTypes.func
};

ModalCommentContent.propTypes = {
  isAllowed: PropTypes.bool
};

export default ModalCommentContent;
