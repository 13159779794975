import api from './api';

export const ordersService = api.injectEndpoints({
  endpoints: builder => ({
    getOrders: builder.query({
      query: () => 'orders',
      providesTags: ['Orders'],
      transformResponse: response => response.result
    }),
    showOrder: builder.query({
      query: id => `orders/${id}`,
      transformResponse: response => response.result,
      providesTags: ['Order']
    }),
    createOrder: builder.mutation({
      query: order => ({
        url: 'orders',
        method: 'POST',
        body: order
      }),
      invalidatesTags: ['Orders']
    }),
    updateOrder: builder.mutation({
      query: ({ id, order }) => ({
        url: `orders/${id}`,
        method: 'PUT',
        body: order
      }),
      invalidatesTags: ['Orders', 'Board', 'Order']
    }),
    deleteOrder: builder.mutation({
      query: id => ({
        url: `orders/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Orders', 'Board']
    }),
    duplicateOrder: builder.mutation({
      query: ({ id, lane, body }) => ({
        url: `orders/${id}/duplicate/${lane}`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['Orders', 'Board']
    })
  })
});

export const {
  useGetOrdersQuery,
  useShowOrderQuery,
  useCreateOrderMutation,
  useUpdateOrderMutation,
  useDeleteOrderMutation,
  useDuplicateOrderMutation
} = ordersService;
