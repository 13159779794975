import React from 'react';
import ForgetPasswordForm from 'components/authentication/ForgetPasswordForm';

const ForgetPassword = () => {
  return (
    <div className="text-center">
      <h5 className="mb-0"> ¿Olvidaste tu contraseña?</h5>
      <small>
        Ingresa tu correo electrónico y te enviaremos un correo para recuperar
        tu contraseña
      </small>
      <ForgetPasswordForm />
    </div>
  );
};

export default ForgetPassword;
