import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const UserDropdownList = ({
  viewers,
  users,
  handleAdd,
  handleRemove,
  avatarSize
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <>
      <Form className="px-3 mb-2">
        <Form.Control
          size="sm"
          type="text"
          placeholder="Busca un miembro del tablero"
          onChange={e => setSearchTerm(e.target.value)}
        />
      </Form>
      <ul className="list-unstyled mb-0">
        {viewers
          .filter(user => {
            if (!searchTerm) {
              return user.user;
            } else if (
              user?.user?.name
                .toLowerCase()
                .includes(searchTerm.toLowerCase().trim())
            ) {
              return user;
            }
          })
          .map(user => (
            <li
              key={user.id}
              onClick={() => {
                const reviewer = users.find(
                  reviewer => reviewer.user.id === user.user.id
                );

                if (reviewer) {
                  handleRemove(reviewer.id);
                } else {
                  handleAdd(user.user.id);
                }
              }}
            >
              <Button
                className={`w-100 ${
                  !users.find(reviewer => reviewer.user.id === user.user.id)
                    ? 'nav-link-card-details border-0'
                    : ''
                }`}
                size="sm"
                variant={
                  users.find(reviewer => reviewer.user.id === user.user.id)
                    ? 'falcon-default'
                    : ''
                }
              >
                <Flex
                  alignItems="center"
                  className="px-3 py-1 text-decoration-none"
                >
                  {user.user?.avatar ? (
                    <Avatar
                      src={user.user.avatar}
                      className="me-2"
                      size={avatarSize}
                    />
                  ) : (
                    <Avatar
                      name={user.user?.name}
                      className="me-2"
                      size={avatarSize}
                    />
                  )}
                  <div className="flex-1">
                    <h6 className="mb-0">
                      {user.user?.name}
                      {users.find(u => u.user?.id === user.user?.id) && (
                        <FontAwesomeIcon icon="check" className="ms-2" />
                      )}
                    </h6>
                  </div>
                </Flex>
              </Button>
            </li>
          ))}
      </ul>
    </>
  );
};

UserDropdownList.propTypes = {
  viewers: PropTypes.array,
  users: PropTypes.array,
  handleAdd: PropTypes.func,
  handleRemove: PropTypes.func,
  avatarSize: PropTypes.string
};

export default UserDropdownList;
