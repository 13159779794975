import React from 'react';
import Avatar from './Avatar';
import PropTypes from 'prop-types';

const UserAvatar = ({ id, users }) => {
  const user = users.find(user => user?.id === id);

  return user.avatar ? (
    <Avatar src={user.avatar} size="l" />
  ) : (
    <Avatar name={user.name} size="l" />
  );
};

UserAvatar.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      avatar: PropTypes.string,
      name: PropTypes.string
    })
  )
};

export default UserAvatar;
