import React from 'react';
import PasswordResetForm from 'components/authentication/PasswordResetForm';

const PasswordReset = () => (
  <div className="text-center">
    <h5>Reestablecer Contraseña</h5>
    <PasswordResetForm />
  </div>
);

export default PasswordReset;
