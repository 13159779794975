import api from './api';

export const usersService = api.injectEndpoints({
  endpoints: builder => ({
    getUsers: builder.query({
      query: () => 'users',
      // Add a tag to this query for cache invalidation
      providesTags: ['Users'],
      transformResponse: response => response.result
    }),
    showUser: builder.query({
      query: id => `users/${id}`,
      transformResponse: response => response.result
    }),
    createUser: builder.mutation({
      query: user => ({
        url: 'users',
        method: 'POST',
        body: user
      }),
      // Invalidate the 'Users' tag after successful user creation
      invalidatesTags: ['Users']
    }),
    updateUser: builder.mutation({
      query: ({ id, user }) => ({
        url: `users/${id}`,
        method: 'PUT',
        body: user
      }),
      invalidatesTags: ['Users']
    }),
    deleteUser: builder.mutation({
      query: id => ({
        url: `users/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Users']
    }),
    modifyPassword: builder.mutation({
      query: ({ password, newPassword }) => ({
        url: 'modify_password',
        method: 'POST',
        body: { password, new_password: newPassword }
      })
    }),
    updateCurrentUser: builder.mutation({
      query: ({ id, user }) => ({
        url: `users/${id}`,
        method: 'PUT',
        body: user
      }),
      invalidatesTags: ['Users']
    })
  })
});

export const {
  useGetUsersQuery,
  useShowUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useModifyPasswordMutation,
  useUpdateCurrentUserMutation
} = usersService;
