import React, { useContext, useEffect, useState } from 'react';
import {
  CloseButton,
  Col,
  Modal,
  Row,
  Form,
  Button,
  Card
} from 'react-bootstrap';
import Background from 'components/common/Background';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ModalMediaContent from './ModalMediaContent';
import GroupMember from './GroupMember';
import { members } from 'data/kanban';
import ModalLabelContent from './ModalLabelContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalAttachmentContent from './ModalAttachmentContent';
import ModalCommentContent from './ModalCommentContent';
import { KanbanContext } from 'context/Context';
import ModalSidebar from './ModalSidebar';
import {
  useShowOrderQuery,
  useUpdateOrderMutation
} from 'services/orders.service';
import { isIterableArray } from 'helpers/utils';
import FileInput from 'components/common/FileInput';
import { useCreateAttachmentMutation } from 'services/attachment.service';
import ModalChecklistContent from './ModalChecklistContent';
import { useAuth } from 'hooks/useAuth';

const KanbanModal = () => {
  const [description, setDescription] = useState('');
  const [isEditingDescription, setIsEditingDescription] = useState(false);

  const {
    kanbanState: { kanbanModal },
    kanbanDispatch
  } = useContext(KanbanContext);

  const { data: order } = useShowOrderQuery(kanbanModal.modalContent.id, {
    skip: !kanbanModal.modalContent.id
  });

  const [updateOrder] = useUpdateOrderMutation();
  const [createAttachment] = useCreateAttachmentMutation();

  const user = useAuth();

  const isAdmin = user?.role === 'admin';

  const { id, slug, orderId } = useParams();
  const navigate = useNavigate();

  const groups = order?.groups;

  useEffect(() => {
    if (order && order?.description) {
      setDescription(order.description);
    } else {
      setDescription('');
    }
  }, [order]);

  useEffect(() => {
    if (orderId) {
      kanbanDispatch({ type: 'OPEN_KANBAN_MODAL', payload: { id: orderId } });
      navigate(`/board/${id}/${slug}/order/${orderId}`);
    }
  }, [orderId]);

  const handleClose = () => {
    kanbanDispatch({ type: 'TOGGLE_KANBAN_MODAL' });
    navigate(`/board/${id}/${slug}`);
  };

  const handleSubmit = e => {
    e.preventDefault();
    updateOrder({ id: order.id, order: { description } });
    setIsEditingDescription(false);
  };

  const handleChangeImage = e => {
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    updateOrder({ id: order.id, order: formData });
  };

  const handleUpload = e => {
    const formData = new FormData();
    const file = e.target.files[0];
    const fileName = file?.name;
    const extension = fileName.split('.').pop();

    formData.append('src', file);
    formData.append('order_id', order.id);
    formData.append('name', fileName);
    formData.append('title', fileName);
    formData.append('archive_type', extension.toLowerCase());

    createAttachment(formData);
  };

  const findReviewer = () => {
    const findReviewer = order?.reviewers.find(
      reviewer => reviewer.user.id === user.id
    );
    return findReviewer;
  };

  const isAllowed = isAdmin || findReviewer();

  return (
    <Modal
      show={kanbanModal.show}
      size="lg"
      onHide={handleClose}
      contentClassName="border-0"
      dialogClassName="mt-6"
    >
      {order?.image && (
        <div className="position-relative overflow-hidden py-8 cover-image">
          <Background image={order.image} className="rounded-top-lg" />
          <FileInput
            controlId="order-image"
            labelClassName="cover-image-file-input"
            acceptFiles="image/*"
            onChange={handleChangeImage}
          >
            <FontAwesomeIcon icon="image" className="me-2" />
            <span>Cambiar Imagen</span>
          </FileInput>
        </div>
      )}
      <div className="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
        <CloseButton
          onClick={handleClose}
          className="btn btn-sm btn-circle d-flex flex-center transition-base"
        />
      </div>
      <Modal.Body className="p-0">
        <div className="bg-light rounded-top-lg px-4 py-3">
          <h4 className="mb-1">{order?.name}</h4>
          <p className="fs--2 mb-0">
            Creado por{' '}
            <Link className="text-600 fw-semi-bold">{order?.user.name}</Link>
          </p>
        </div>
        <div className="p-4">
          <Row>
            <Col lg={isAllowed ? 9 : 12}>
              <ModalMediaContent title="Revisores" icon="user">
                {order?.reviewers ? (
                  <GroupMember
                    users={order.reviewers}
                    addMember
                    showMember={6}
                    avatarSize="xl"
                    isAllowed={isAllowed}
                  />
                ) : (
                  <GroupMember
                    users={members}
                    addMember
                    showMember={6}
                    avatarSize="xl"
                  />
                )}
              </ModalMediaContent>

              {order?.tags && (
                <ModalMediaContent title="Etiquetas" icon="tag">
                  <ModalLabelContent
                    labels={order.tags}
                    isAllowed={isAllowed}
                  />
                </ModalMediaContent>
              )}

              <ModalMediaContent title="Descripción" icon="align-left">
                {isEditingDescription ? (
                  <Form onSubmit={handleSubmit}>
                    <Form.Control
                      as="textarea"
                      value={description ?? ''}
                      onChange={e => setDescription(e.target.value)}
                    />
                    <Button
                      className="mt-2 me-1"
                      type="submit"
                      variant="primary"
                      size="sm"
                    >
                      Guardar
                    </Button>
                    <Button
                      className="mt-2"
                      variant="secondary"
                      size="sm"
                      onClick={() => {
                        setIsEditingDescription(false);
                        setDescription(order.description);
                      }}
                    >
                      Cancelar
                    </Button>
                  </Form>
                ) : order?.description ? (
                  <p
                    className="text-word-break fs--1"
                    style={{ cursor: isAllowed && 'pointer' }}
                    onClick={() => {
                      if (isAllowed) {
                        setIsEditingDescription(true);
                      }
                    }}
                  >
                    {description}
                  </p>
                ) : (
                  <Card
                    className="w-100 text-break"
                    style={{
                      cursor: 'pointer',
                      transition: 'filter 0.3s',
                      filter: 'brightness(100%)'
                    }}
                    onMouseOver={e =>
                      (e.currentTarget.style.filter = 'brightness(120%)')
                    }
                    onMouseOut={e =>
                      (e.currentTarget.style.filter = 'brightness(100%)')
                    }
                    onClick={() => setIsEditingDescription(true)}
                  >
                    <Card.Body>
                      <Card.Text className="text-word-break fs--1">
                        Agrega una descripción...
                      </Card.Text>
                    </Card.Body>
                  </Card>
                )}
              </ModalMediaContent>

              <ModalMediaContent
                title="Archivos Adjuntos"
                icon="paperclip"
                headingClass="d-flex justify-content-between"
                headingContent={
                  isAllowed && (
                    <FileInput
                      controlId="attachment-file-input"
                      labelClassName="btn btn-falcon-default btn-sm fs--2"
                      onChange={handleUpload}
                    >
                      <FontAwesomeIcon icon="plus" size="sm" />
                    </FileInput>
                  )
                }
              >
                {isIterableArray(order?.attachments) ? (
                  <ModalAttachmentContent isAllowed={isAllowed} />
                ) : (
                  <p className="text-word-break fs--1 font-weight-bold">
                    Aquí aparecerán los archivos que agregues
                  </p>
                )}
              </ModalMediaContent>

              {isIterableArray(groups) &&
                groups.map(group => (
                  <ModalChecklistContent
                    key={group.id}
                    group={group}
                    isAllowed={isAllowed}
                  />
                ))}

              <ModalMediaContent
                title="Comentarios"
                icon={['far', 'comment']}
                headingClass="mb-3"
              >
                <ModalCommentContent isAllowed={isAllowed} />
              </ModalMediaContent>

              {/* <ModalMediaContent
                title="Activity"
                icon="list-ul"
                headingClass="mb-3"
                isHr={false}
              >
                <ModalActivityContent />
              </ModalMediaContent> */}
            </Col>
            {isAllowed && (
              <Col lg={3}>
                <ModalSidebar />
              </Col>
            )}
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default KanbanModal;
