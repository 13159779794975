import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row, Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import envelope from 'assets/img/icons/spot-illustrations/16.png';
import OTPInput from 'react-otp-input';
import { useVerifyTokenMutation } from 'services/authentication.service';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setToken } from 'slices/authentication.slice';

const ConfirmMailContent = ({ titleTag: TitleTag }) => {
  const [otp, setOtp] = useState(0);
  const [verifyToken] = useVerifyTokenMutation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const checkToken = async () => {
      if (otp.toString().length === 6) {
        try {
          await verifyToken({ token: otp }).unwrap();
          dispatch(setToken(otp));
          navigate('/authentication/reset-password');
        } catch (error) {
          if (error.status === 422) {
            toast.error(error.data.error, {
              theme: 'colored',
              position: 'top-center'
            });
          } else {
            toast.error(
              'Ha ocurrido un error inesperado, intentálo de nuevo más tarde',
              { theme: 'colored', position: 'top-center' }
            );
          }
        }
      }
    };

    checkToken();
  }, [otp]);

  return (
    <>
      <img
        className="d-block mx-auto mb-4"
        src={envelope}
        alt="sent"
        width={100}
      />
      <TitleTag>¡Por favor revisa tu correo electrónico!</TitleTag>
      <p>
        Un código de verificación ha sido enviado a tu correo. Por favor ingresa
        el código de verificación para poder reestablecer tu contraseña.
      </p>

      <Row className="justify-content-center align-items-center">
        <Col xs="auto">
          <Form>
            <OTPInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span>-</span>}
              renderInput={props => (
                <Form.Control
                  {...props}
                  style={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '5px',
                    textAlign: 'center'
                  }}
                />
              )}
            />
          </Form>
        </Col>
      </Row>

      <Button
        as={Link}
        color="primary"
        size="sm"
        className="mt-3"
        to={`/authentication`}
      >
        <FontAwesomeIcon
          icon="chevron-left"
          transform="shrink-4 down-1"
          className="me-1"
        />
        Regresar a inicio de sesión
      </Button>
    </>
  );
};

ConfirmMailContent.propTypes = {
  titleTag: PropTypes.string
};

ConfirmMailContent.defaultProps = { layout: 'simple', titleTag: 'h4' };

export default ConfirmMailContent;
