import api from './api';

export const groupsService = api.injectEndpoints({
  endpoints: builder => ({
    getGroups: builder.query({
      query: () => 'groups',
      providesTags: ['Groups'],
      transformResponse: res => res.result
    }),
    showGroup: builder.query({
      query: id => `groups/${id}`
    }),
    createGroup: builder.mutation({
      query: group => ({
        url: 'groups',
        method: 'POST',
        body: group
      }),
      invalidatesTags: ['Groups', 'Board', 'Order']
    }),
    updateGroup: builder.mutation({
      query: ({ id, group }) => ({
        url: `groups/${id}`,
        method: 'PUT',
        body: group
      }),
      invalidatesTags: ['Groups', 'Board', 'Order']
    }),
    deleteGroup: builder.mutation({
      query: id => ({
        url: `groups/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Groups', 'Board', 'Order']
    })
  })
});

export const {
  useGetGroupsQuery,
  useShowGroupQuery,
  useCreateGroupMutation,
  useUpdateGroupMutation,
  useDeleteGroupMutation
} = groupsService;
