import api from './api';

export const boardsService = api.injectEndpoints({
  endpoints: builder => ({
    getBoards: builder.query({
      query: () => 'boards',
      providesTags: ['Boards'],
      transformResponse: response => response.result
    }),
    showBoard: builder.query({
      query: id => `boards/${id}`,
      providesTags: ['Board'],
      transformResponse: response => response.result
    }),
    createBoard: builder.mutation({
      query: board => ({
        url: 'boards',
        method: 'POST',
        body: board
      }),
      invalidatesTags: ['Boards']
    }),
    updateBoard: builder.mutation({
      query: ({ id, board }) => ({
        url: `boards/${id}`,
        method: 'PUT',
        body: board
      }),
      invalidatesTags: ['Boards']
    }),
    deleteBoard: builder.mutation({
      query: id => ({
        url: `boards/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Boards']
    })
  })
});

export const {
  useGetBoardsQuery,
  useShowBoardQuery,
  useCreateBoardMutation,
  useUpdateBoardMutation,
  useDeleteBoardMutation
} = boardsService;
