import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Dropdown, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Background from 'components/common/Background';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import Avatar, { AvatarGroup } from 'components/common/Avatar';
import { Draggable } from 'react-beautiful-dnd';
import AppContext, { KanbanContext } from 'context/Context';
import {
  useDeleteOrderMutation,
  useShowOrderQuery,
  useUpdateOrderMutation
} from 'services/orders.service';
import DeleteConfirmation from 'components/common/DeleteConfirmation';
import { useAuth } from 'hooks/useAuth';
import { isIterableArray } from 'helpers/utils';
import { useNavigate, useParams } from 'react-router-dom';

const TaskDropMenu = ({ setShow, setShowDialog }) => {
  const {
    config: { isRTL }
  } = useContext(AppContext);

  return (
    <Dropdown
      onClick={e => e.stopPropagation()}
      align="end"
      className="font-sans-serif"
    >
      <Dropdown.Toggle
        variant="falcon-default"
        size="sm"
        className="kanban-item-dropdown-btn hover-actions dropdown-caret-none"
      >
        <FontAwesomeIcon icon="ellipsis-h" transform="shrink-2" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="py-0" align={isRTL ? 'start' : 'end'}>
        <Dropdown.Item onClick={() => setShow(true)}>Renombrar</Dropdown.Item>
        <Dropdown.Item
          className="text-danger"
          onClick={() => setShowDialog(true)}
        >
          Eliminar
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const TaskCard = ({ task, index, isAllowed }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [name, setName] = useState(task.name);

  const { kanbanDispatch } = useContext(KanbanContext);
  const { id, image, tags } = task;
  const currentUser = useAuth();

  const { data: order } = useShowOrderQuery(id);

  const { id: boardId, slug } = useParams();
  const navigate = useNavigate();

  const reviewers = order?.reviewers;
  const attachments = order?.attachments;

  const checklist = useMemo(
    () => order?.groups.flatMap(group => group.tasks),
    [order?.groups]
  );

  const completed = useMemo(
    () => checklist?.filter(task => task.done),
    [checklist]
  );

  const [updateOrder] = useUpdateOrderMutation();
  const [deleteOrder, { isLoading }] = useDeleteOrderMutation();

  const handleModalOpen = () => {
    kanbanDispatch({ type: 'OPEN_KANBAN_MODAL', payload: { id } });
    navigate(`/board/${boardId}/${slug}/order/${id}`);
  };

  const handleSubmit = () => {
    updateOrder({ id, order: { name } });
    setShowInput(false);
  };

  const handleRemoveTaskCard = () => deleteOrder(id);

  // styles we need to apply on draggables
  const getItemStyle = isDragging => ({
    cursor: isDragging ? 'grabbing' : 'pointer',
    transform: isDragging ? 'rotate(-2deg)' : ''
  });

  return (
    <Draggable draggableId={`task${id}`} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={provided.draggableProps.style}
          className="kanban-item"
        >
          <Card
            style={getItemStyle(snapshot.isDragging)}
            className="kanban-item-card hover-actions-trigger"
            onClick={handleModalOpen}
          >
            {image && (
              <div
                className={`position-relative rounded-top-lg overflow-hidden py-8`}
              >
                <Background image={image} />
              </div>
            )}
            <Card.Body>
              {isAllowed && (
                <div className="position-relative">
                  <TaskDropMenu
                    id={id}
                    setShow={setShowInput}
                    setShowDialog={setShowDialog}
                  />
                </div>
              )}
              {tags && (
                <div className="mb-2">
                  {tags.map(label => (
                    <SoftBadge
                      key={label.text}
                      bg={label.type}
                      className="py-1 me-1 mb-1"
                    >
                      {label.text}
                    </SoftBadge>
                  ))}
                </div>
              )}
              {showInput ? (
                <Form onSubmit={handleSubmit}>
                  <Form.Control
                    placeholder="Ingresa el nombre de la orden"
                    value={name}
                    onChange={({ target }) => setName(target.value)}
                    onClick={e => e.stopPropagation()}
                  />
                </Form>
              ) : (
                <p
                  className="mb-0 fw-medium font-sans-serif stretched-link"
                  dangerouslySetInnerHTML={{ __html: name }}
                />
              )}

              {(reviewers || attachments || checklist) && (
                <div className="kanban-item-footer cursor-default">
                  <div className="text-500 z-index-2">
                    {reviewers &&
                      reviewers.find(
                        reviewer => reviewer.user.id === currentUser.id
                      ) && (
                        <span className="me-2">
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>Estás asignado a esta tarea</Tooltip>
                            }
                          >
                            <span>
                              <FontAwesomeIcon icon="eye" />
                            </span>
                          </OverlayTrigger>
                        </span>
                      )}

                    {isIterableArray(attachments) && (
                      <span className="me-2">
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Attachments</Tooltip>}
                        >
                          <span>
                            <FontAwesomeIcon
                              icon="paperclip"
                              className="me-1"
                            />
                          </span>
                        </OverlayTrigger>
                        <span>{attachments.length}</span>
                      </span>
                    )}

                    {isIterableArray(checklist) && (
                      <span className="me-2">
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Attachments</Tooltip>}
                        >
                          <span>
                            <FontAwesomeIcon icon="check" className="me-1" />
                          </span>
                        </OverlayTrigger>
                        <span>{`${completed?.length}/${checklist?.length}`}</span>
                      </span>
                    )}
                  </div>
                  <div className="z-index-2">
                    {reviewers && (
                      <AvatarGroup>
                        {reviewers.map(reviewer => (
                          <OverlayTrigger
                            key={reviewer.id}
                            placement="top"
                            overlay={<Tooltip>{reviewer.user.name}</Tooltip>}
                          >
                            <div>
                              {reviewer.user.avatar ? (
                                <Avatar
                                  size="l"
                                  src={reviewer.user.avatar}
                                  className="ms-n1"
                                />
                              ) : (
                                <Avatar
                                  size="l"
                                  name={reviewer.user.name}
                                  className="ms-n1"
                                />
                              )}
                            </div>
                          </OverlayTrigger>
                        ))}
                      </AvatarGroup>
                    )}
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
          <DeleteConfirmation
            show={showDialog}
            onHide={() => setShowDialog(false)}
            msg={`¿Deseas eliminar la orden ${name}?`}
            isLoading={isLoading}
            handleDelete={handleRemoveTaskCard}
          />
        </div>
      )}
    </Draggable>
  );
};

TaskDropMenu.propTypes = {
  id: PropTypes.number.isRequired,
  setShow: PropTypes.func,
  setShowDialog: PropTypes.func
};

TaskCard.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string,
    tags: PropTypes.array,
    reviewers: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string
      })
    ),
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        url: PropTypes.string
      })
    ),
    labels: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        text: PropTypes.string
      })
    ),
    checklist: PropTypes.shape({
      totalCount: PropTypes.number,
      completed: PropTypes.number
    }),
    done: PropTypes.bool
  }),
  index: PropTypes.number,
  isAllowed: PropTypes.bool
};

export default TaskCard;
