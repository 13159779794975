import React, { useMemo } from 'react';
import ProfileBanner from './Banner';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useShowUserQuery } from 'services/users.service';
import { useGetViewersQuery } from 'services/viewers.service';
import { useGetBoardsQuery } from 'services/boards.service';
import Board from './Board';
import { useAuth } from 'hooks/useAuth';

const Profile = () => {
  const { id } = useParams();

  const { data: user } = useShowUserQuery(id);

  const { data: viewers } = useGetViewersQuery();
  const { data: boards } = useGetBoardsQuery();

  const currentUser = useAuth();

  const filteredViewers = useMemo(
    () => viewers?.filter(viewer => viewer.user_id === user?.id),
    [viewers, user]
  );

  const filteredBoards = useMemo(
    () =>
      boards?.filter(board =>
        filteredViewers?.find(viewer => viewer.board_id === board.id)
      ),
    [boards, filteredViewers]
  );

  if (!user) return <Spinner />;

  const isAllowed = currentUser.id === user.id || currentUser.role === 'admin';

  return (
    <>
      <ProfileBanner />
      {/* <Row className="g-3 mb-3">
        <Col lg={8}>
          <ProfileIntro />
          <Associations
            associations={associations.slice(0, 4)}
            colBreakpoints={{ sm: 6 }}
          />
          <ActivityLog className="mt-3" activities={activities.slice(5, 9)} />
          <Photos />
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar">
            <Experiences />
            <Education />
            <Events
              className="mb-3"
              cardTitle="Events"
              events={events.slice(2, 5)}
            />
          </div>
        </Col>
      </Row> */}
      {isAllowed &&
        filteredBoards?.map(board => (
          <Board key={board.id} boardId={board.id} userId={user.id} />
        ))}
    </>
  );
};

export default Profile;
