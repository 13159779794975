import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const FileInput = ({
  children,
  controlId,
  formClassName,
  labelClassName,
  acceptFiles,
  onChange
}) => {
  return (
    <Form className={formClassName}>
      <Form.Group controlId={controlId}>
        <Form.Label className={labelClassName}>{children}</Form.Label>
        <Form.Control
          type="file"
          className="d-none"
          accept={acceptFiles}
          onChange={onChange}
        />
      </Form.Group>
    </Form>
  );
};

FileInput.propTypes = {
  children: PropTypes.node.isRequired,
  controlId: PropTypes.string.isRequired,
  formClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  acceptFiles: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default FileInput;
