import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import coverSrc from 'assets/img/generic/4.jpg';
import Flex from 'components/common/Flex';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import ProfileBanner from '../ProfileBanner';
import { useShowUserQuery } from 'services/users.service';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const Banner = () => {
  const { id } = useParams();

  const { data: user } = useShowUserQuery(id);

  const { name, position, avatar, role, company, phone, whatsapp, email } =
    user;

  const items = [
    {
      icon: 'envelope',
      title: email
    },
    {
      icon: 'phone',
      title: phone
    },
    {
      icon: faWhatsapp,
      title: whatsapp
    }
  ];

  const getRole = () => {
    const roles = [
      {
        id: 'user',
        name: 'Usuario'
      },
      {
        id: 'sales',
        name: 'Ventas'
      },
      {
        id: 'admin',
        name: 'Administrador'
      }
    ];

    const findRole = roles.find(roleState => roleState.id === role);

    return findRole.name;
  };

  const roleName = getRole();

  return (
    <ProfileBanner>
      <ProfileBanner.Header avatar={avatar} coverSrc={coverSrc} name={name} />
      <ProfileBanner.Body>
        <Row>
          <Col lg={8}>
            <h4 className="mb-1">{name}</h4>
            <h5 className="fs-0 fw-normal">{position}</h5>
            <p className="text-500">{roleName}</p>
            {/* <Button variant="falcon-primary" size="sm" className="px-3">
              Following
            </Button>
            <Button variant="falcon-default" size="sm" className="px-3 ms-2">
              Message
            </Button> */}
            <div className="border-dashed border-bottom my-4 d-lg-none" />
          </Col>
          <Col className="ps-2 ps-lg-3">
            {items.map((item, index) => (
              <Link key={index} to="#!">
                <Flex alignItems="center" className="mb-2">
                  <FontAwesomeIcon
                    icon={item.icon}
                    className="fs-3 me-2 text-700"
                  />
                  <div className="flex-1">
                    <h6 className="mb-0">{item.title}</h6>
                  </div>
                </Flex>
              </Link>
            ))}
            <Link to="#!">
              <Flex alignItems="center" className="mb-2">
                <img src={company.logo} width={30} className="me-2" />
                <div className="flex-1">
                  <h6 className="mb-0">{company.name}</h6>
                </div>
              </Flex>
            </Link>
          </Col>
        </Row>
      </ProfileBanner.Body>
    </ProfileBanner>
  );
};

export default Banner;
