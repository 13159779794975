import api from './api';

export const tagsService = api.injectEndpoints({
  endpoints: builder => ({
    getTags: builder.query({
      query: () => 'tags',
      transformResponse: response => response.result
    }),
    getLabels: builder.query({
      query: () => 'tag_orders',
      providesTags: ['Tags'],
      transformResponse: response => response.result
    }),
    showTag: builder.query({
      query: id => `tags/${id}`
    }),
    createTag: builder.mutation({
      query: tag => ({
        url: 'tag_orders',
        method: 'POST',
        body: tag
      }),
      invalidatesTags: ['Tags', 'Board', 'Order']
    }),
    updateTag: builder.mutation({
      query: ({ id, tag }) => ({
        url: `tags/${id}`,
        method: 'PUT',
        body: tag
      }),
      invalidatesTags: ['Tags', 'Board']
    }),
    deleteTag: builder.mutation({
      query: id => ({
        url: `tag_orders/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Tags', 'Board', 'Order']
    })
  })
});

export const {
  useGetTagsQuery,
  useGetLabelsQuery,
  useShowTagQuery,
  useCreateTagMutation,
  useUpdateTagMutation,
  useDeleteTagMutation
} = tagsService;
