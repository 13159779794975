import api from './api';

export const authenticationService = api.injectEndpoints({
  endpoints: builder => ({
    authenticate: builder.mutation({
      queryFn: async (credentials, _queryApi, _extraOptions, fetchWithBQ) => {
        try {
          // First, authenticate and get the token
          const authResult = await fetchWithBQ({
            url: 'authenticate',
            method: 'POST',
            body: credentials
          });

          // Extract the token from the first request's response
          const token = authResult.data.auth_token;

          // Use the token to fetch the current user
          const userResult = await fetchWithBQ({
            url: 'user/me',
            headers: { Authorization: `Bearer ${token}` }
          });

          const user = {
            ...userResult.data.result,
            token
          };
          // Return both the token and user information
          return { data: user };
        } catch (error) {
          // Handle errors
          return { error: error.message };
        }
      }
    }),
    recoveryPassword: builder.mutation({
      query: email => ({
        url: 'recovery_password',
        method: 'POST',
        body: email
      })
    }),
    verifyToken: builder.mutation({
      query: token => ({
        url: 'verify_token',
        method: 'POST',
        body: token
      })
    }),
    resetPassword: builder.mutation({
      query: ({ token, password }) => ({
        url: `reset_password?token=${token}`,
        method: 'POST',
        body: password
      })
    })
  })
});

export const {
  useAuthenticateMutation,
  useRecoveryPasswordMutation,
  useVerifyTokenMutation,
  useResetPasswordMutation
} = authenticationService;
