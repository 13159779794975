import React from 'react';
import ProfileBanner from '../ProfileBanner';
import coverSrc from 'assets/img/generic/4.jpg';
import { Col, Row } from 'react-bootstrap';
import ProfileSettings from './ProfileSettings';
import ChangePassword from './ChangePassword';
import { useAuth } from 'hooks/useAuth';

const Settings = () => {
  const user = useAuth();

  return (
    <>
      <ProfileBanner>
        <ProfileBanner.Header
          coverSrc={coverSrc}
          avatar={user.avatar}
          className="mb-8"
          isEditable
        />
      </ProfileBanner>
      <Row className="g-3">
        <Col lg={8}>
          <ProfileSettings />
          {/* <ExperiencesSettings /> */}
          {/* <EducationSettings /> */}
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar">
            {/* <AccountSettings /> */}
            {/* <BillingSettings /> */}
            <ChangePassword />
            {/* <DangerZone /> */}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Settings;
