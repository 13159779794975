import React, { useContext } from 'react';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext, { KanbanContext } from 'context/Context';
import {
  useCreateTagMutation,
  useDeleteTagMutation,
  useGetTagsQuery
} from 'services/tags.service';
import PropTypes from 'prop-types';
import TagsFormChecklist from './TagsFormChecklist';

const ModalLabelContent = ({ labels, isAllowed }) => {
  const {
    kanbanState: { kanbanModal }
  } = useContext(KanbanContext);

  const {
    config: { isRTL }
  } = useContext(AppContext);

  const { data: tags } = useGetTagsQuery();
  const [addTag] = useCreateTagMutation();
  const [deleteTag] = useDeleteTagMutation();

  const order = kanbanModal.modalContent.id;

  const handleTagChange = (e, tag) => {
    if (e.target.checked) {
      addTag({ tag_id: tag.id, order_id: order });
    } else {
      const label = labels.find(label => label.id === tag.id);

      if (label) {
        deleteTag(label.tag_order_id);
      }
    }
  };

  return (
    <Flex>
      {labels?.map(tag => (
        <SoftBadge bg={tag.type} className="me-1 py-2" key={tag.id}>
          {tag.text}
        </SoftBadge>
      ))}
      {isAllowed && (
        <Dropdown drop={isRTL ? 'start' : 'end'} autoClose="outside">
          <Dropdown.Toggle
            variant="secondary"
            size="sm"
            className="px-2 fsp-75 bg-400 border-400 dropdown-caret-none"
          >
            <FontAwesomeIcon icon="plus" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-md">
            {tags && (
              <TagsFormChecklist
                tags={tags}
                labels={labels}
                handleTagChange={handleTagChange}
              />
            )}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </Flex>
  );
};

ModalLabelContent.propTypes = {
  labels: PropTypes.array,
  isAllowed: PropTypes.bool
};

export default ModalLabelContent;
