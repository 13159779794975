import React, { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthSimpleLayout from './AuthSimpleLayout';
import is from 'is_js';
import MainLayout from './MainLayout';
// import SettingsToggle from 'components/settings-panel/SettingsToggle';
// import SettingsPanel from 'components/settings-panel/SettingsPanel';

import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';
import Kanban from 'components/app/kanban/Kanban';
import AppContext from 'context/Context';
import Login from 'components/authentication/simple/Login';
import { Provider } from 'react-redux';
import store from 'store';
import Customers from 'components/app/e-commerce/customers/Customers';
import Settings from 'components/pages/user/settings/Settings';
import Users from 'components/app/e-commerce/users/Users';
import Orders from 'components/app/e-commerce/orders/order-list/Orders';
import Boards from 'components/pages/Boards';
import ForgetPassword from 'components/authentication/simple/ForgetPassword';
import PasswordReset from 'components/authentication/simple/PasswordReset';
import ConfirmMail from 'components/authentication/simple/ConfirmMail';
import Profile from 'components/pages/user/profile/Profile';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition]);

  return (
    <Provider store={store}>
      <Routes>
        <Route path="/authentication" element={<AuthSimpleLayout />}>
          <Route index element={<Login />} />
          <Route path="forgot-password" element={<ForgetPassword />} />
          <Route path="verify-token" element={<ConfirmMail />} />
          <Route path="reset-password" element={<PasswordReset />} />
        </Route>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Boards />} />
          <Route path="users" element={<Users />} />
          <Route path="customers" element={<Customers />} />
          <Route path="orders" element={<Orders />} />
          <Route path="profile/:id" element={<Profile />} />
          <Route path="settings" element={<Settings />} />
          <Route path="board/:id/:slug" element={<Kanban />} />
          <Route path="board/:id/:slug/order/:orderId" element={<Kanban />} />
        </Route>
      </Routes>
      {/* <SettingsToggle /> */}
      {/* <SettingsPanel /> */}
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </Provider>
  );
};

export default Layout;
