import React, { useMemo } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useShowBoardQuery } from 'services/boards.service';
import { useGetReviewersQuery } from 'services/reviewers.service';
import PropTypes from 'prop-types';

const Boards = ({ boardId, userId }) => {
  const navigate = useNavigate();

  const { data: board } = useShowBoardQuery(boardId);
  const { data: reviewers } = useGetReviewersQuery();

  const filteredReviewers = useMemo(
    () => reviewers?.filter(reviewer => reviewer.user_id === userId),
    [reviewers]
  );

  const orders = useMemo(
    () =>
      board?.lanes
        .map(lane => lane.orders)
        .flat()
        .filter(order =>
          filteredReviewers?.find(reviewer => reviewer.order_id === order.id)
        ),
    [board, filteredReviewers]
  );

  if (!board) {
    return <Spinner />;
  }

  return (
    <Card className="p-0 mt-3">
      <Card.Header className="bg-light">
        <Card.Title>{board.name}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row xs={1} md={2} lg={3} className="g-4">
          {orders.map(order => (
            <Col key={order.id}>
              <Card
                role="button"
                onClick={() => {
                  navigate(
                    `/board/${board.id}/${board.name}/order/${order.id}`
                  );
                }}
              >
                {order.image && (
                  <Card.Img
                    variant="top"
                    src={order.image}
                    style={{ objectFit: 'cover', height: '200px' }}
                  />
                )}
                <Card.Header>{order.name}</Card.Header>
              </Card>
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Boards;

Boards.propTypes = {
  boardId: PropTypes.number,
  userId: PropTypes.number
};
