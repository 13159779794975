import React, { useEffect, useState, useMemo } from 'react';
import { Button, Card, Dropdown, Form, Modal, Spinner } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import OrdersTableHeader from './OrdersTableHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import {
  useDeleteOrderMutation,
  useGetOrdersQuery,
  useUpdateOrderMutation
} from 'services/orders.service';
import { useGetUsersQuery } from 'services/users.service';
import Flex from 'components/common/Flex';
import { formatDate } from '@fullcalendar/react';
import { useGetBoardsQuery } from 'services/boards.service';
import { toast } from 'react-toastify';
import { useGetCompaniesQuery } from 'services/companies.service';
import { Link } from 'react-router-dom';

const columns = [
  {
    accessor: 'name',
    Header: 'Order',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const [show, setShow] = useState();
      const [orderName, setOrderName] = useState('');
      const [updateOrder] = useUpdateOrderMutation();

      const { id, name } = rowData.row.original;

      useEffect(() => {
        const handleKeyDown = event => {
          if (event.key === 'Escape') {
            setOrderName('');
            setShow(false);
          }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, []);

      const handleSubmit = async e => {
        e.preventDefault();
        if (!orderName) {
          setShow(false);
          return;
        }

        await updateOrder({ id, order: { name: orderName } });
        setShow(false);
      };

      return (
        <Flex
          className="gap-2"
          alignItems="center"
          role="button"
          onClick={() => setShow(true)}
        >
          <Link>
            <strong>#{id}</strong>
          </Link>{' '}
          {show ? (
            <Form onSubmit={handleSubmit}>
              <Form.Control
                type="text"
                value={orderName}
                onChange={({ target }) => setOrderName(target.value)}
              />
            </Form>
          ) : (
            <strong>{name}</strong>
          )}
        </Flex>
      );
    }
  },
  {
    accessor: 'user',
    Header: 'Creado por'
  },
  {
    accesor: 'company',
    Header: 'Compañía',
    Cell: rowData => {
      const { company } = rowData.row.original;

      return (
        <Flex alignItems="center">
          <div className="flex-1">
            <p className="mb-0 fs--1">{company}</p>
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'created_at',
    Header: 'Fecha',
    Cell: rowData => {
      const { created_at } = rowData.row.original;

      return (
        <Flex alignItems="center">
          <div className="flex-1">
            <p className="mb-0 fs--1">
              {formatDate(created_at, {
                month: 'long',
                year: 'numeric',
                day: 'numeric',
                locale: 'es'
              })}
            </p>
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'status',
    Header: 'Aceptar',
    headerProps: {
      className: 'text-center'
    },
    cellProps: {
      className: 'fs-0'
    },
    Cell: rowData => {
      const { data: boards } = useGetBoardsQuery();
      const [updateOrder, { isLoading, reset }] = useUpdateOrderMutation();
      const [show, setShow] = useState(false);
      const [selectedBoard, setSelectedBoard] = useState(null);

      const order = rowData.row.original;

      useEffect(() => {
        if (show === false) {
          reset(); // Reset the mutation state
        }
      }, [show, reset]);

      const handleSelectBoard = (id, name) => {
        setSelectedBoard({ id, name });
      };

      const closeModal = () => {
        setShow(false);
        setSelectedBoard(null);
      };

      const handleConfirm = async () => {
        if (selectedBoard) {
          try {
            await updateOrder({
              id: order.id,
              order: { board_id: selectedBoard.id }
            }).unwrap();
            toast.success(
              <div>
                Orden enviada a{' '}
                <Link
                  to={`/board/${selectedBoard.id}/${selectedBoard.name}/order/${order.id}`}
                >
                  {selectedBoard.name}
                </Link>
              </div>,
              {
                position: 'top-right'
              }
            );
          } catch (error) {
            toast.error('Ha ocurrido un error inesperado');
          }
          closeModal();
        }
      };

      return (
        <>
          <Flex alignItems="center" justifyContent="center">
            <Button variant="primary" size="sm" onClick={() => setShow(true)}>
              Enviar a Tablero
            </Button>
          </Flex>

          <Modal show={show} onHide={() => closeModal()}>
            <Modal.Header closeButton>
              <Modal.Title>Tableros</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {boards?.map(board => (
                <Button
                  key={board.id}
                  variant={
                    selectedBoard?.id === board.id
                      ? 'primary'
                      : 'outline-primary'
                  }
                  className="m-1"
                  onClick={() => handleSelectBoard(board.id, board.name)}
                >
                  {board.name}
                </Button>
              ))}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                size="sm"
                onClick={() => closeModal()}
              >
                Cancelar
              </Button>
              <Button
                variant="primary"
                onClick={handleConfirm}
                disabled={!selectedBoard}
                size="sm"
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  'Confirmar'
                )}
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
  },
  {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    },
    Cell: rowData => {
      const [deleteOrder] = useDeleteOrderMutation();
      const orderId = rowData.row.original.id;

      return (
        <CardDropdown iconClassName="fs--1">
          <div className="py-2">
            <Dropdown.Item
              className="text-danger"
              onClick={() => deleteOrder(orderId)}
            >
              Eliminar
            </Dropdown.Item>
          </div>
        </CardDropdown>
      );
    }
  }
];

const Orders = () => {
  const { data: ordersData, isLoading: isLoadingOrders } = useGetOrdersQuery();
  const { data: usersData, isLoading: isLoadingUsers } = useGetUsersQuery();
  const { data: companiesData, isLoading: isLoadingCompanies } =
    useGetCompaniesQuery();

  const currentOrders = useMemo(() => {
    // Create a lookup object for users
    const userLookup = {};
    usersData?.forEach(user => {
      userLookup[user.id] = user.name;
    });

    const companyLookup = {};
    companiesData?.forEach(company => {
      companyLookup[company.id] = company.name;
    });

    return ordersData
      ?.filter(order => order.lane_id === null)
      .map(order => ({
        ...order,
        user: userLookup[order.user_id] || userLookup[order.user?.id],
        company: companyLookup[order.company_id]
      }));
  }, [ordersData, usersData, companiesData]);

  if (isLoadingOrders || isLoadingUsers || isLoadingCompanies) {
    return (
      <Flex className="vh-100" justifyContent="center" alignItems="center">
        <Spinner animation="border" role="status" />
      </Flex>
    );
  }

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={currentOrders}
      selection={false}
      sortable
      pagination
      perPage={10}
    >
      <Card className="mb-3">
        <Card.Header>
          <OrdersTableHeader table />
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTablePagination table />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

export default Orders;
