import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useRecoveryPasswordMutation } from 'services/authentication.service';
import { useNavigate } from 'react-router-dom';

const ForgetPasswordForm = () => {
  // State
  const [email, setEmail] = useState('');
  const [recoveryPassword, { isLoading }] = useRecoveryPasswordMutation();

  const navigate = useNavigate();

  // Handler
  const handleSubmit = async e => {
    e.preventDefault();
    if (email) {
      try {
        await recoveryPassword({ email }).unwrap();
        navigate('/authentication/verify-token');
      } catch (error) {
        if (error.status === 404) {
          toast.error(error.data.error, {
            theme: 'colored',
            position: 'top-center'
          });
        } else {
          toast.error(
            'Ha ocurrido un error inesperado, inténtelo de nuevo más tarde',
            { theme: 'colored', position: 'top-center' }
          );
        }
      }
    }
  };

  return (
    <Form className="mt-4" onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Control
          placeholder={'Correo electrónico'}
          value={email}
          name="email"
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Button className="w-100" type="submit" disabled={!email}>
          {isLoading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            'Enviar Correo'
          )}
        </Button>
      </Form.Group>

      {/* <Link className="fs--1 text-600" to="#!">
        I can't recover my account using this page
        <span className="d-inline-block ms-1"> &rarr;</span>
      </Link> */}
    </Form>
  );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = { layout: 'simple' };

export default ForgetPasswordForm;
