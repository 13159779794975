import React from 'react';
import LoginForm from 'components/authentication/LoginForm';
import { Col, Row } from 'react-bootstrap';

const Login = () => (
  <>
    <Row className="text-center justify-content-center">
      <Col xs="auto pb-4">
        <h3>Inicia Sesión</h3>
      </Col>
    </Row>
    <LoginForm />
  </>
);

export default Login;
