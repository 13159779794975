import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const apiUrl = 'https://admin.nanosciencelabs.net/api/v1/';

const baseQuery = fetchBaseQuery({
  baseUrl: apiUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.currentUser?.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  }
});

const api = createApi({
  reducerPath: 'api',
  baseQuery,
  endpoints: () => ({})
});

export default api;
