import React, { useContext, useEffect, useState } from 'react';
import { Nav, Dropdown, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { useShowBoardQuery } from 'services/boards.service';
import AppContext, { KanbanContext } from 'context/Context';
import {
  useDeleteOrderMutation,
  useShowOrderQuery
} from 'services/orders.service';
import { useOrderId } from 'hooks/useOrderId';
import AddToCard from './AddToCard';
import MoveCardForm from './MoveCardForm';
import CopyCardForm from './CopyCardForm';

const ModalSidebar = () => {
  const {
    config: { isRTL }
  } = useContext(AppContext);

  const { kanbanDispatch } = useContext(KanbanContext);

  const orderId = useOrderId();

  const [addToCardMenu, setAddToCardMenu] = useState([
    { icon: 'user', title: 'Revisores', link: '#!' },
    { icon: 'tag', title: 'Etiquetas', link: '#!' },
    { icon: 'paperclip', title: 'Archivos', link: '#!' },
    { icon: 'check', title: 'Lista de tareas', link: '#!' }
  ]);

  const [actionMenu] = useState([
    { icon: ['far', 'copy'], title: 'Copiar', link: '#!' },
    { icon: 'arrow-right', title: 'Mover', link: '#!' },
    { icon: 'trash-alt', title: 'Eliminar', link: '#!' }
  ]);

  const { id } = useParams();

  const { data: board } = useShowBoardQuery(id);
  const { data: order } = useShowOrderQuery(orderId);
  const [deleteOrder] = useDeleteOrderMutation();

  useEffect(() => {
    if (order && !order?.image) {
      setAddToCardMenu([
        { icon: 'user', title: 'Revisores', link: '#!' },
        { icon: 'tag', title: 'Etiquetas', link: '#!' },
        { icon: 'paperclip', title: 'Archivos', link: '#!' },
        { icon: 'check', title: 'Lista de tareas', link: '#!' },
        { icon: 'image', title: 'Imagen', link: '#!' }
      ]);
    } else {
      setAddToCardMenu([
        { icon: 'user', title: 'Revisores', link: '#!' },
        { icon: 'tag', title: 'Etiquetas', link: '#!' },
        { icon: 'paperclip', title: 'Archivos', link: '#!' },
        { icon: 'check', title: 'Lista de tareas', link: '#!' }
      ]);
    }
  }, [order]);

  const handleDelete = () => {
    kanbanDispatch({ type: 'TOGGLE_KANBAN_MODAL' });
    deleteOrder(order.id);
  };

  if (!board || !order) {
    return <Spinner />;
  }

  return (
    <>
      <AddToCard menuList={addToCardMenu} board={board} order={order} />

      <h6 className="mt-3">Acciones</h6>
      {actionMenu.map(menu => (
        <Nav key={menu.title} className="flex-lg-column fs--1">
          <Nav.Item className="me-2 me-lg-0">
            <Dropdown>
              <Dropdown.Toggle
                as={Nav.Link}
                className="nav-link-card-details dropdown-caret-none"
              >
                <FontAwesomeIcon icon={menu.icon} className="me-2" />
                {menu.title}
              </Dropdown.Toggle>
              <Dropdown.Menu
                align={isRTL ? 'end' : 'start'}
                className="dropdown-md"
              >
                {menu.title === 'Copiar' ? (
                  <>
                    <Dropdown.Header>Copiar Orden</Dropdown.Header>
                    <Dropdown.ItemText>
                      <CopyCardForm />
                    </Dropdown.ItemText>
                  </>
                ) : menu.title === 'Mover' ? (
                  <>
                    <Dropdown.Header>Mover Orden</Dropdown.Header>
                    <Dropdown.ItemText>
                      <MoveCardForm />
                    </Dropdown.ItemText>
                  </>
                ) : (
                  <>
                    <Dropdown.Header>Eliminar Orden</Dropdown.Header>
                    <Dropdown.ItemText>
                      <h6 className="text-xl">
                        ¿Estás seguro de eliminar esta orden?
                      </h6>
                      <Button
                        variant="danger w-100"
                        size="sm"
                        onClick={() => {
                          if (menu.title === 'Eliminar') {
                            handleDelete();
                          }
                        }}
                      >
                        Eliminar
                      </Button>
                    </Dropdown.ItemText>
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Nav.Item>
        </Nav>
      ))}
    </>
  );
};

export default ModalSidebar;
